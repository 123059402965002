<template>
  <div :id="elementoDinamico.id" @click="emitirEvento(props.elemento)" class="video" :class="{ ocultarElemento: elementoDinamico.hidden }" :style="estiloDinamico">
    <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    <iframe :style="estiloDinamicoBg" :src="elementoDinamico.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['video'])

const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

const estiloDinamicoBg = computed(() => ({
  backgroundImage: `url('${elementoDinamico.value.image || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png'}')`,
  borderRadius: `${elementoDinamico.value.borderRadius?.top}px ${elementoDinamico.value.borderRadius?.right}px ${elementoDinamico.value.borderRadius?.bottom}px ${elementoDinamico.value.borderRadius?.left}px`
}))

const estiloDinamico = computed(() => ({
  width: `${elementoDinamico.value.maxWidth}px`,
  height: `${elementoDinamico.value.maxHeight}px`,
  margin: `${elementoDinamico.value.margin?.top}px ${elementoDinamico.value.margin?.right}px ${elementoDinamico.value.margin?.bottom}px ${elementoDinamico.value.margin?.left}px`,
  borderRadius: `${elementoDinamico.value.borderRadius?.top}px ${elementoDinamico.value.borderRadius?.right}px ${elementoDinamico.value.borderRadius?.bottom}px ${elementoDinamico.value.borderRadius?.left}px`
}))

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('video', elemento.elementoSelecionado)
  }
}
</script>

<style scoped>
iframe {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--cor-preto);
  background-size: cover;
  background-position: center;
  pointer-events: none;
}

.capa img {
  width: 100%;
  max-width: 150px;
  pointer-events: none;
}

section.tela .site .conteudo .capa img {
  max-width: 80px;
}
</style>
