<template>
  <div :id="elementoDinamico.id" :class="{ ocultarElemento: elementoDinamico.hidden }" :style="estiloDinamico">
    <div
      @click="emitirEvento(props.elemento)"
      class="background"
      :class="{ ocultarElemento: elementoDinamico?.hidden }"
      :style="`
    background: linear-gradient(${elementoDinamico?.gradientDeg}deg, #${elementoDinamico?.gradientPrimary}, #${elementoDinamico?.gradientSecondary});
    border-radius:
    ${elementoDinamico?.borderRadius?.top}px
    ${elementoDinamico?.borderRadius?.right}px
    ${elementoDinamico?.borderRadius?.bottom}px
    ${elementoDinamico?.borderRadius?.left}px;
  `">
      <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['item'])
const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

const estiloDinamico = computed(() => ({
  backgroundColor: `#${elementoDinamico.value.backgroundColor}`,
  backgroundImage: `url('${elementoDinamico.value.image || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png'}')`,
  width: `${elementoDinamico.value.maxWidth}px`,
  height: `${elementoDinamico.value.maxHeight}px`,
  borderRadius: `${elementoDinamico.value.borderRadius?.top}px ${elementoDinamico.value.borderRadius?.right}px ${elementoDinamico.value.borderRadius?.bottom}px ${elementoDinamico.value.borderRadius?.left}px`
}))

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('item', elemento.elementoSelecionado)
  }
}
</script>
