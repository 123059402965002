import { defineStore } from "pinia"

export const useStoreQuadro3Mobile = defineStore('storeQuadro3Mobile', {
  state: () => ({

    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 30,
        bottom: 120,
        left: 30
      },
    },

    // Fundo
    fundoItensUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    // Fundo
    fundoItensDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png',
    },

    // Fundo Um

    fundoUmQuadroUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

    fundoUmQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

    fundoUmQuadroTres: {
      id: '',
      hidden: true,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

    fundoUmQuadroQuatro: {
      id: '',
      hidden: true,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

    // Fundo Dois

    fundoDoisQuadroUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

    fundoDoisQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

    fundoDoisQuadroTres: {
      id: '',
      hidden: true,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

    fundoDoisQuadroQuatro: {
      id: '',
      hidden: true,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/placeholder.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 100,
        right: 0,
        bottom: 100,
        left: 0
      },
    },

  })
})
