import { defineStore } from "pinia"

export const useStoreTopo4Mobile = defineStore('storeTopo4Mobile', {
  state: () => ({

    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 80,
        right: 30,
        bottom: 120,
        left: 30
      },
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png',
    },

    // Video
    videoUm: {
      id: '',
      hidden: false,
      url: 'https://www.youtube.com/embed/zFAFXBlSsTU?si=XuMcnMIK1glpnADA',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      maxWidth: 320,
      maxHeight: 180,
      margin: {
        top: 0,
        right: 5,
        bottom: 10,
        left: 5
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
    },

    // Video
    videoDois: {
      id: '',
      hidden: false,
      url: 'https://www.youtube.com/embed/zFAFXBlSsTU?si=XuMcnMIK1glpnADA',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      maxWidth: 320,
      maxHeight: 180,
      margin: {
        top: 0,
        right: 5,
        bottom: 10,
        left: 5
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
    },

    // Video
    videoTres: {
      id: '',
      hidden: false,
      url: 'https://www.youtube.com/embed/zFAFXBlSsTU?si=XuMcnMIK1glpnADA',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      maxWidth: 320,
      maxHeight: 180,
      margin: {
        top: 0,
        right: 5,
        bottom: 10,
        left: 5
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
    },

    // Video
    videoQuatro: {
      id: '',
      hidden: false,
      url: 'https://www.youtube.com/embed/zFAFXBlSsTU?si=XuMcnMIK1glpnADA',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      maxWidth: 320,
      maxHeight: 180,
      margin: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 5
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
    },

  })
})
