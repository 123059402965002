import { defineStore } from 'pinia'

export const useStoreSecao5 = defineStore('storeSecao5', {
  state: () => ({
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '0D0F1E',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      padding: {
        top: 0,
        right: 80,
        bottom: 160,
        left: 80
      },
      gradienActive: false
    },
    fundoTexto: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 40
      }
    },
    divisor: {
      id: '',
      hidden: false,
      image: 'https://upload.unicpages.com/imagens/c8c3bb1c92907d53bf41d7-divisor.png'
    },
    imagemPrincipal: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/6d6103144dd0019c1e3c18-foto-3.png',
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    iconeTag: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/0dc8c3bb1c92907d53bf41-coroa-icone.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoTag: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 600',
      fontSize: 18,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum'
    },
    titulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 60,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: 'D69D3A',
      textAlign: 'left',
      lineHeight: 120,
      maxWidth: 656,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem ipsum *dolor sit amet,* consectetur '
    },
    subtitulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 673,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum, nisl sapien dignissim erat, sed condimentum erat arcu at lectus. Nullam non dictum ex, id aliquet nunc. Vivamus sit amet orci dapibus.'
    },
    botao: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 600',
      fontSize: 17,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: '292C42',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 70,
        bottom: 20,
        left: 70
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
