<template>
  <div id="secoes">
    <!-- Seções de Navbar -->
    <div class="nome um">
      <h2>Seções em Código</h2>
      <p>Dezenas de seções feitas no código HTML, CSS e Javascript</p>
    </div>
    <div class="secoes">
      <div class="secao" v-for="secao in state.secoes.filter((s) => s.categoria === 'secao')" :key="secao">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="{ backgroundImage: `url(${secao.capaUrl})` }"></div>
          </div>
        </div>
        <div class="texto">
          <h4>{{ secao.tecnologias }}</h4>
          <h3>{{ secao.titulo }}</h3>
          <p>{{ secao.descricao }}</p>
          <button class="um upgrade" @click="router.push('/planos')" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">Upgrade</button>
          <button class="um" @click="secao.acao" v-else>Adicionar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, reactive } from 'vue'
import { useStoreCriar, useStoreAjustes, useStoreModal, useStorePerfil } from '@stores'
import { helperId } from '@helpers'
import { useRouter } from 'vue-router'

import { useStoreSecao4 } from '@components/criar/secoes/codigo/secao4.js'
import { useStoreSecao5 } from '@components/criar/secoes/codigo/secao5.js'
import { useStoreHtml2Mobile } from '@components/criar/secoes/padrao/html/2/versaoMobile/store/index.js'

const emitter = inject('emitter')
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()
const storeModal = useStoreModal()
const storePerfil = useStorePerfil()
const router = useRouter()

function adicionarSecao(secao, storeFunc) {
  const id = helperId.random(12)
  const store = sanitizarStore(storeFunc())

  storeCriar.adicionarSecao(secao, id, false)
  storeAjustes.adicionarSecao(secao, id, store, false)
  emitter.emit('renderizarComponentesTela')
  emitter.emit('atualizarCamadasSite')
  storeModal.fecharModal('secoesMobile')
}

function sanitizarStore(store) {
  const clone = { ...store }

  for (const key in clone) {
    if (key.startsWith('_')) delete clone[key]
    if (key.startsWith('$')) delete clone[key]
  }

  return JSON.parse(JSON.stringify(clone))
}

const state = reactive({
  secoes: [
    {
      tecnologias: 'HTML, CSS e JS',
      titulo: 'Crie do zero',
      descricao: 'Crie sua seção do absoluto zero com HTML, CSS e Javascript',
      capaUrl: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/capa/html-2.png',
      acao: () => adicionarSecao('Html2Mobile', useStoreHtml2Mobile),
      categoria: 'secao'
    },
    {
      tecnologias: '1ª | HTML, CSS e JavaScript',
      titulo: 'Contador com Data Final',
      descricao: 'Seção que usa JavaScript para exibir um contador que você define a data Final',
      capaUrl: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/codigo/secao-codigo-5.png',
      acao: () => adicionarSecao('Html2Mobile', useStoreSecao5),
      categoria: 'secao'
    },
    {
      tecnologias: '2ª | HTML, CSS e JavaScript',
      titulo: 'Contagem Regressiva com Redirect',
      descricao: 'Seção que usa JavaScript e redireciona o usuário após 10 segundos',
      capaUrl: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/codigo/secao-codigo-4.png',
      acao: () => adicionarSecao('Html2Mobile', useStoreSecao4),
      categoria: 'secao'
    }
  ]
})
</script>

<style scoped>
#secoes {
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: fadeIn 0.3s linear;
}

.secoes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.nome {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 30px 0 0 0;
  border-top: 2px solid var(--cor-cinza-3);
}

.nome.um {
  padding: 0;
  margin: 0 0 20px 0;
  border-top: none;
}

.nome h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 10px 0 0;
}

.nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.secao {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 10px 20px 0;
  position: relative;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.secao .capa {
  width: 100%;
  max-width: 300px;
}

.secao .capa .tamanho {
  position: relative;
  padding-bottom: 56%;
  width: 100%;
  height: 100%;
}

.secao .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-preto);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px 0 0 10px;
}

.secao .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 10px 20px;
  border-radius: 0 0 10px 10px;
}

.secao .texto button {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  border-radius: 10px;
  padding: 10px 13px;
  transition: all 0.3s;
}

.secao .texto button.upgrade {
  background-color: var(--cor-laranja);
}

.secao .texto button.upgrade:hover {
  background-color: var(--cor-laranja-escuro);
}

.secao .texto button:hover {
  background-color: var(--cor-azul-escuro);
}

.secao .texto h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.secao .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 10px 0 10px 0;
}

.secao .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 0 0 15px 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .secao {
    flex-direction: column;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
    border-radius: 10px;
    background-color: var(--cor-cinza-3);
  }

  .secao .capa {
    max-width: 100%;
  }

  .secao .capa .tamanho .imagem {
    border-radius: 10px 10px 0 0;
  }

  .secao .texto {
    padding: 25px 20px;
    border-radius: 0 0 10px 10px;
  }

  .secao .texto p {
    line-height: 1.5;
  }

  .nome {
    flex-direction: column;
    align-items: flex-start;
  }

  .nome h2 {
    margin: 0 0 5px 0;
  }
}
</style>
