<template>
  <div class="img">
    <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    <img :id="elementoDinamico.id" @click="emitirEvento(props.elemento)" :class="{ ocultarElemento: elementoDinamico.hidden }" :src="`${elementoDinamico.image || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/imagem-avatar-rosto.png'}`" :style="estiloDinamico" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['imagem'])

const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

const estiloDinamico = computed(() => ({
  maxWidth: `${elementoDinamico.value.maxWidth}px`,
  minWidth: elementoDinamico.value.maxWidth < 200 ? `${elementoDinamico.value.maxWidth}px` : 'auto',
  margin: `${elementoDinamico.value.margin?.top}px ${elementoDinamico.value.margin?.right}px ${elementoDinamico.value.margin?.bottom}px ${elementoDinamico.value.margin?.left}px`,
  borderRadius: `${elementoDinamico.value.borderRadius?.top}px ${elementoDinamico.value.borderRadius?.right}px ${elementoDinamico.value.borderRadius?.bottom}px ${elementoDinamico.value.borderRadius?.left}px`
}))

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('imagem', elemento.elementoSelecionado)
  }
}
</script>
