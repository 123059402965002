<template>
  <section class="contato">
    <!-- Titulo -->
    <div class="titulo">
      <img class="icone" src="https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-trans-unlimited.svg" />
      <div class="texto">
        <h3>Quer um profissional criando sua landing page?</h3>
        <p>Nossa equipe de especialistas está pronta para criar um site incrível para o seu negócio, com um design profissional, alta performance e total personalização para destacar sua marca no digital.</p>
      </div>
      <a target="_blank" href="https://api.whatsapp.com/send?phone=1831993895&text=Ol%C3%A1%2C%20tenho%20interesse%20nos%20profissionais%20da%20UnicPages">
        <img src="https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-unlimited.svg" />
        <p>Falar com profissional</p>
      </a>
    </div>
  </section>
</template>

<script setup></script>

<style scoped>
section.contato {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 40px 20px;
  width: 100%;
  overflow: hidden;
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
}

.titulo .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.titulo .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
  width: 100%;
  max-width: 650px;
}

.titulo img.icone {
  width: 100%;
  max-width: 60px;
}

.titulo a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  padding: 15px 30px;
  border-radius: 10px;
  transition: all 0.3s;
}

.titulo a:hover {
  background-color: var(--cor-cinza-4);
}

.titulo a img {
  width: 100%;
  max-width: 12px;
  margin: 0 5px 0 0;
}

.titulo a p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

/*
Responsivo
*/
@media screen and (max-width: 1024px) {
  section.contato {
    padding: 0 20px 50px 20px;
  }

  .titulo {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .titulo .texto {
    padding: 20px 0 15px 0;
  }

  .titulo .texto h3 {
    font-size: var(--f2);
  }

  .titulo .texto p {
    font-size: var(--f1);
  }

  .titulo img.icone {
    max-width: 50px;
  }

  .titulo a {
    padding: 13px 25px;
  }

  .titulo a img {
    max-width: 10px;
  }

  .titulo a p {
    font-size: var(--f1);
  }
}
</style>
