<template>
  <section class="planos">
    <div class="test" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">
      <img src="https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-free.svg" />
      <h3>Programa de Parceiros Unic</h3>
      <p>Nosso programa de parceiros é uma oportunidade incrível para você fazer uma renda extra indicando e ganhando 30% de comissão recorrente. Faça seu upgrade agora mesmo para ter acesso.</p>
      <button @click="router.push('/planos')">
        <Svgs nome="estrela" />
        Fazer Upgrade
      </button>
    </div>
    <!-- Titulo -->
    <div v-else class="titulo">
      <div class="texto">
        <h3>Programa de Parceiros</h3>
        <p>Receba comissão recorrente por cada cliente que assinar a UnicPages através da sua indicação. 30% de comissão recorrente enquanto seu cliente for assinante.</p>
      </div>
      <a target="_blank" href="https://api.whatsapp.com/send?phone=1831993895&text=Quero%20ser%20afiliado%20Unic">Quero ser parceiro Unic</a>
    </div>
    <!-- Itens -->
    <div class="itens">
      <div v-for="(plano, index) in planos" :key="index" :class="'item ' + plano.class">
        <div class="nome">
          <img :src="plano.simbolo" class="simbolo" />
          <h3>{{ plano.nome }}</h3>
        </div>
        <div class="preco">
          <div v-for="(preco, tipo) in plano.precos" :key="tipo" :class="tipo">
            <div class="valor">
              <h2>{{ preco.valor }}</h2>
              <div class="parte">
                <h4>{{ preco.texto }}</h4>
                <h6>{{ preco.detalhe }}</h6>
              </div>
            </div>
            <h5>
              {{ preco.cheio }}
            </h5>
            <p>{{ preco.desconto }}</p>
          </div>
        </div>
        <div class="beneficios">
          <div class="beneficio" :class="{ bloqueado: !item.liberado }" v-for="item in plano.beneficios">
            <div class="icone">
              <Svgs :nome="item.icon" />
            </div>
            <p>{{ item.titulo }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import { useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const router = useRouter()

const planos = reactive([
  // Silver
  {
    class: 'silver',
    simbolo: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-flat-silver.svg',
    nome: 'Assinou Plano Silver',
    precos: {
      anual: {
        valor: 'R$ 38',
        texto: '/ mês',
        detalhe: 'Cobrado Anualmente',
        cheio: 'ou R$ 388 à vista',
        desconto: 'Você recebe até R$ 111,58 de comissão'
      }
    },
    beneficios: [
      { icon: 'cursor', liberado: true, titulo: 'Crie até 3 landing pages' },
      { icon: 'dominio', liberado: true, titulo: 'Configure até 3 domínios' },
      { icon: 'infinito', liberado: true, titulo: 'Acessos ilimitados em suas LPs' },
      { icon: 'editar', liberado: true, titulo: 'Personalização ilimitada' },
      { icon: 'cadeado', liberado: true, titulo: 'Certificado SSL grátis já ativado' },
      { icon: 'whatsapp', liberado: true, titulo: 'Suporte 24h por Whatsapp' },
      { icon: 'atualizacoes', liberado: false, titulo: 'Acesso antecipado à atualizações' },
      { icon: 'call', liberado: false, titulo: 'Call de Implementação (1h30)' }
    ]
  },
  // Gold
  {
    class: 'gold',
    simbolo: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-flat-gold.svg',
    nome: 'Assinou Plano Gold',
    precos: {
      anual: {
        valor: 'R$ 78',
        texto: '/ mês',
        detalhe: 'Cobrado Anualmente',
        cheio: 'ou R$ 784 à vista ',
        desconto: 'Você recebe até R$ 226,22 de comissão'
      }
    },
    beneficios: [
      { icon: 'cursor', liberado: true, titulo: 'Crie até 10 landing pages' },
      { icon: 'dominio', liberado: true, titulo: 'Configure até 10 domínios' },
      { icon: 'infinito', liberado: true, titulo: 'Acessos ilimitados em suas LPs' },
      { icon: 'editar', liberado: true, titulo: 'Personalização ilimitada' },
      { icon: 'cadeado', liberado: true, titulo: 'Certificado SSL grátis já ativado' },
      { icon: 'whatsapp', liberado: true, titulo: 'Suporte 24h por Whatsapp' },
      { icon: 'atualizacoes', liberado: true, titulo: 'Acesso antecipado à atualizações' },
      { icon: 'call', liberado: false, titulo: 'Call de Implementação (1h30)' }
    ]
  },
  // Diamond
  {
    class: 'diamond',
    simbolo: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-flat-diamond.svg',
    nome: 'Assinou Plano Diamond',
    precos: {
      anual: {
        valor: 'R$ 206',
        texto: '/ mês',
        detalhe: 'Cobrado Anualmente',
        cheio: 'ou R$ 2.052 à vista |',
        desconto: 'Você recebe até R$ 593,31 de comissão'
      }
    },
    beneficios: [
      { icon: 'cursor', liberado: true, titulo: 'Crie até 50 landing pages' },
      { icon: 'dominio', liberado: true, titulo: 'Configure até 50 domínios' },
      { icon: 'infinito', liberado: true, titulo: 'Acessos ilimitados em suas LPs' },
      { icon: 'editar', liberado: true, titulo: 'Personalização ilimitada' },
      { icon: 'cadeado', liberado: true, titulo: 'Certificado SSL grátis já ativado' },
      { icon: 'whatsapp', liberado: true, titulo: 'Suporte 24h por Whatsapp' },
      { icon: 'atualizacoes', liberado: true, titulo: 'Acesso antecipado à atualizações' },
      { icon: 'call', liberado: true, titulo: 'Call de Implementação (1h30)' }
    ]
  }
])
</script>

<style scoped>
section.planos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px 20px 20px;
  width: 100%;
  overflow: hidden;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0 50px 0;
}

.test img {
  width: 100%;
  max-width: 50px;
  margin: 0 0 20px 0;
}

.test h3 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
  text-align: center;
}

.test p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  text-align: center;
  line-height: 1.5;
  width: 100%;
  max-width: 650px;
  margin: 5px 0 10px 0;
}

.test button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-align: center;
  background-color: var(--cor-azul);
  padding: 15px 30px;
  border-radius: 10px;
  transition: all 0.3s;
}

.test button:hover {
  background-color: var(--cor-azul-escuro);
}

.test button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 30px 0;
}

.titulo .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.titulo .texto h3 {
  font-family: var(--bold);
  font-size: var(--f6);
  color: var(--cor-branco);
}

.titulo .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 10px 0 20px 0;
  width: 100%;
  max-width: 600px;
}

.titulo a {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  line-height: 1.5;
  padding: 15px 40px;
  border-radius: 10px;
  transition: all 0.3s;
}

.titulo a:hover {
  background-color: var(--cor-azul-escuro);
}

/* Itens */
.itens {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 0 0;
}

.itens .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(33.33% - 10px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 40px 40px 20px 40px;
  position: relative;
  transition: all 0.3s;
}

.itens .item .nome {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.itens .item .nome img {
  width: 100%;
  max-width: 30px;
  margin: 0 10px 0 0;
  z-index: 1;
}

.itens .item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.itens .item .nome h4 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: #33b0b8;
  padding: 5px 15px;
  border-radius: 50px;
  position: absolute;
  bottom: -15px;
}

/* Preço */
.itens .item .preco {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0 10px 0;
  position: relative;
  overflow: hidden;
  min-height: 100px;
}

.itens .item .preco .anual {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  transition: all 0.6s;
}

.itens .item .preco .valor {
  display: flex;
  align-items: center;
  width: 100%;
}

.itens .item .preco h2 {
  font-family: var(--bold);
  font-size: var(--f6);
  color: var(--cor-branco);
  margin: 0 10px 0 0;
}

.itens .item .preco h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.itens .item .preco h6 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 3px 0 0 0;
}

.itens .item .preco h5 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 10px 0 0 0;
}

.itens .item .preco p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-verde);
  text-decoration: underline;
  margin: 5px 0 20px 0;
}

.itens .item .preco .parte:nth-child(1) {
  align-items: flex-end;
  text-align: right;
}

.itens .item .preco .parte {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Botão */
.itens .item a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul);
  padding: 20px 40px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s;
}

.itens .item a:hover {
  background-color: var(--cor-azul-escuro);
}

.itens .item a p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-transform: capitalize;
}
.itens .item img {
  width: 100%;
  max-width: 15px;
  margin: 0 10px 0 0;
}

/* Benefícios */
.beneficios {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.beneficios .beneficio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 20px 0;
}

.beneficios .beneficio.bloqueado p {
  color: var(--cor-cinza-5);
  text-decoration: line-through;
}

.beneficios .beneficio.bloqueado .icone {
  background-color: var(--cor-cinza-2);
  border: 2px solid var(--cor-cinza-3);
}

.beneficios .beneficio.bloqueado .icone svg {
  fill: var(--cor-cinza-4);
}

.beneficios .beneficio p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.beneficios .beneficio .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.beneficios .beneficio .icone svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
}

/*
Responsivo
*/
@media screen and (max-width: 1024px) {
  section.planos {
    padding: 20px 20px 0px 20px;
  }

  .test h3 {
    font-size: var(--f4);
  }

  .titulo {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px 20px;
  }

  .titulo .texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .titulo .texto h3 {
    font-size: var(--f4);
    width: 100%;
    max-width: 250px;
  }

  .titulo .texto p {
    font-size: var(--f1);
    margin: 10px 0 0 0;
  }

  .titulo a {
    margin: 15px 0 0 0;
  }

  /* Itens */
  .itens {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .itens .item {
    width: 100%;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .itens .item .nome img {
    max-width: 25px;
    margin: 0 8px 0 0;
  }

  .itens .item .nome h3 {
    font-size: var(--f2);
  }

  /* Preço */
  .itens .item .preco {
    margin: 10px 0 20px 0;
    min-height: 100px;
  }

  .itens .item .preco .valor {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .itens .item .preco h2 {
    font-family: var(--bold);
    font-size: var(--f5);
    color: var(--cor-branco);
    margin: 0 10px 0 0;
  }

  .itens .item .preco h4 {
    font-size: var(--f1);
  }

  .itens .item .preco h6 {
    font-size: var(--f0);
  }

  .itens .item .preco h5 {
    font-size: var(--f1);
  }

  /* Botão */
  .itens .item a {
    padding: 15px 30px;
    margin: 10px 0 0 0;
  }

  /* Benefícios */

  .beneficios .beneficio {
    margin: 0 0 15px 0;
  }

  .beneficios .beneficio p {
    font-size: var(--f1);
  }

  .beneficios .beneficio .icone {
    width: 25px;
    height: 25px;
  }

  .beneficios .beneficio .icone svg {
    width: 10px;
    min-width: 10px;
    max-height: 10px;
  }
}
</style>
