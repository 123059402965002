<template>
  <Modal nome="editarSite">
    <div class="conteudo">
      <label class="um">
        Nome do site
        <span>*</span>
      </label>
      <input v-model="state.nomeDoSite" type="text" autocomplete="off" spellcheck="false" placeholder="Ex: Página de Vendas" @keydown.enter="criarSite" />

      <label>
        Descrição do site
        <span>*</span>
      </label>
      <textarea v-model="state.descricaoDoSite" autocomplete="off" spellcheck="false" placeholder="Ex: Foco em vendas de produtos" @keydown.enter="criarSite"></textarea>

      <label>
        URL do seu site
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>unic.page/</p>
        <input v-model="state.hotlinkSite" @input="validarHotlink()" autocomplete="off" spellcheck="false" type="text" placeholder="Digite o hotlink" @keydown.enter="criarSite" />
      </div>

      <label>Imagem de capa</label>
      <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
        <span></span>
        <div class="previa" :style="`background-image: url('${state.fotoCapa || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/capa-site-unic.png'}')`">
          <Svgs v-if="!state.fotoCapa" nome="arquivo" />
        </div>
        <div class="texto">
          <h3>{{ state.nomeArquivo || 'Subir Capa' }}</h3>
          <p>{{ state.tamanhoArquivo || 'Tamanho recomendado: 800x160' }}</p>
        </div>
        <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
      </div>

      <BotaoSalvar nome="Salvar Alterações" @click="salvarDados" />
    </div>
  </Modal>
</template>

<script setup>
import { reactive, ref, onMounted, inject } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import ApiUpload from '@/api/upload/api-upload.js'
import Svgs from '@svgs'

const storePages = useStorePages()
const storeModal = useStoreModal()
const emitter = inject('emitter')
const inputFile = ref(null)

const state = reactive({
  nomeDoSite: '',
  descricaoDoSite: '',
  fotoCapa: '',
  hotlinkSite: '',
  hotlinkOriginal: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  loading: false
})

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 800
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.fotoCapa = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de capa' })
    })
}

function validarHotlink() {
  let hotlink = state.hotlinkSite

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlinkSite = hotlink
}

async function salvarDados() {
  const hotlinkAlterado = state.hotlinkOriginal !== state.hotlinkSite

  const payload = {
    idPage: storePages.page?._id,
    nome: state.nomeDoSite,
    descricao: state.descricaoDoSite,
    imagemCapa: state.fotoCapa,
    cor: 'FFFFFF'
  }

  if (hotlinkAlterado) payload.hotlink = state.hotlinkSite

  const edicaoOk = await storePages.alterarDados(payload)
  if (edicaoOk !== true) return

  storeModal.fecharModal('editarSite')

  if (hotlinkAlterado) {
    window.location.assign('/criar/' + state.hotlinkSite)
  } else {
    emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Site atualizado!' })
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
}

function preencherDados() {
  state.nomeDoSite = storePages.page?.nome
  state.descricaoDoSite = storePages.page?.descricao
  state.hotlinkSite = storePages.page?.hotlink
  state.hotlinkOriginal = storePages.page?.hotlink
  state.fotoCapa = storePages.page?.imagemCapa
}

function limparCampos() {
  state.nomeDoSite = ''
  state.descricaoDoSite = ''
  state.hotlinkSite = ''
  state.hotlinkOriginal = ''
  state.fotoCapa = ''
}

onMounted(() => {
  emitter.on('carregarCampos', preencherDados)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
