import { defineStore } from 'pinia'

export const useStoreSecao25 = defineStore('storeSecao25', {
  state: () => ({
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      backgroundColor: 'EFF1F7',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 60,
        right: 80,
        bottom: 80,
        left: 80
      },
      gradienActive: false
    },
    fundoTextoUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    fundoTextoDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    fundoTextoTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    fundoTextoQuatro: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    logo: {
      id: '',
      hidden: false,
      link: 'https://unicpages.com',
      linkTarget: '_self',
      image: 'https://arquivos.levelmember.com/imagens/web/logo-level.svg',
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    titulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 16,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 332,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna '
    },
    subtitulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 18,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'email@email.com'
    },
    tituloDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem'
    },
    tituloTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Ipsum'
    },
    tituloQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Amet'
    },
    botaoOpcao1: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao2: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao3: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao4: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 4',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao5: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao6: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao7: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao8: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 8',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao9: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao10: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao11: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoOpcao12: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'ffffff00',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Opção 12',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
