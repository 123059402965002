<template>
  <div class="text">
    <h3>Integrar Active Campaign</h3>
    <div class="activecampaign">
      <button class="abrir" @click="state.exibirQuadro = !state.exibirQuadro" :class="{ ativo: state.exibirQuadro }">
        <Svgs nome="active-campaign-icone" />
        <p>Colar meu código</p>
      </button>
      <div class="balao" :class="{ mostrar: state.exibirQuadro }">
        <div class="titulo">
          <p>Active Campaign</p>
          <button class="fechar" @click="state.exibirQuadro = !state.exibirQuadro">
            <Svgs nome="x" />
          </button>
        </div>
        <div class="conteudo">
          <div class="info">
            <p>Cole no campo abaixo o código do seu ActiveCampaign para extrairmos os dados necessários</p>
            <a class="tutorial" target="_blank" href="https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/tutoriais/tutorial-active-campaign.pdf">
              <Svgs nome="duvida" />
              Veja como fazer
            </a>
            <textarea v-model="state.html" placeholder="Cole o código Active aqui..." rows="5" cols="50"></textarea>
            <button class="extrair" @click="configurar" :disabled="state.textareaVazio">Configurar Integração</button>
          </div>
          <div class="extraido" v-if="state.dadoExtraido">
            <p>Pronto, já extraímos os dados! Faça o teste abaixo para ver se o seu formulário está funcionando 100%:</p>
            <form ref="form" :action="state.dadoExtraido.action" method="POST" id="_form_extracted_" @submit.prevent="submitForm" target="_blank" novalidate>
              <input type="hidden" name="u" :value="state.dadoExtraido.u" />
              <input type="hidden" name="f" :value="state.dadoExtraido.f" />
              <input type="hidden" name="c" :value="state.dadoExtraido.c" />
              <input type="hidden" name="m" :value="state.dadoExtraido.m" />
              <input type="hidden" name="act" value="sub" />
              <input type="hidden" name="v" value="2" />
              <input type="hidden" name="or" :value="state.dadoExtraido.or" />
              <!-- Campo personalizado para UTMs -->
              <div v-if="state.firstCustomField">
                <input type="hidden" :name="state.firstCustomField" :value="state.utmString" />
              </div>
              <label>Nome completo</label>
              <input type="text" id="fullname" name="fullname" placeholder="Digite seu nome" v-model="state.fullname" />
              <label>Email*</label>
              <input type="email" id="email" name="email" placeholder="Digite seu email" required v-model="state.email" />
              <label>Telefone</label>
              <input type="tel" id="phone" name="phone" placeholder="Digite seu telefone" v-model="state.phone" />
              <button type="submit">Testar Integração</button>
            </form>
            <button class="salvar" @click="salvarIntegracao()">
              <Svgs nome="check" />
              Salvar Integração
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const state = reactive({
  text: '',
  fullname: '',
  email: '',
  phone: '',
  html: '',
  exibirQuadro: false,
  textareaVazio: true,
  dadoExtraido: null,
  firstCustomField: '',
  utmString: ''
})

const configurar = () => {
  if (state.textareaVazio) {
    return
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(state.html, 'text/html')

  const form = doc.querySelector('form')
  const action = form.getAttribute('action')
  const u = form.querySelector('input[name="u"]').value
  const f = form.querySelector('input[name="f"]').value
  const c = form.querySelector('input[name="c"]').value
  const m = form.querySelector('input[name="m"]').value
  const act = form.querySelector('input[name="act"]').value
  const v = form.querySelector('input[name="v"]').value
  const or = form.querySelector('input[name="or"]').value

  // Capturar o primeiro campo personalizado dentro do formulário
  let firstCustomField = ''
  form.querySelectorAll('input').forEach((input) => {
    const name = input.getAttribute('name')
    if (!['u', 'f', 'c', 'm', 'act', 'v', 'or', 'fullname', 'email', 'phone', 's'].includes(name) && !firstCustomField) {
      firstCustomField = name
    }
  })

  // Capturar parâmetros UTM da URL
  const urlParams = new URLSearchParams(window.location.search)
  const utmValues = []
  urlParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmValues.push(`${key}=${value}`)
    }
  })
  const utmString = utmValues.join('&')

  // Store extracted data
  state.dadoExtraido = {
    action,
    u,
    f,
    c,
    m,
    act,
    v,
    or
  }

  state.firstCustomField = firstCustomField
  state.utmString = utmString
}

function salvarIntegracao() {
  const elemento = elementoSelecionado()
  elemento.activeCodigoAction = state.dadoExtraido.action
  elemento.activeCodigoU = state.dadoExtraido.u
  elemento.activeCodigoF = state.dadoExtraido.f
  elemento.activeCodigoC = state.dadoExtraido.c
  elemento.activeCodigoM = state.dadoExtraido.m
  elemento.activeCodigoAct = state.dadoExtraido.act
  elemento.activeCodigoV = state.dadoExtraido.v
  elemento.activeCodigoOr = state.dadoExtraido.or
  elemento.activeUtms = state.firstCustomField
  state.exibirQuadro = false
}

const submitForm = () => {
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = state.dadoExtraido.action
  form.target = '_blank'

  Object.entries({
    fullname: state.fullname,
    email: state.email,
    phone: state.phone,
    u: state.dadoExtraido.u,
    f: state.dadoExtraido.f,
    c: state.dadoExtraido.c,
    m: state.dadoExtraido.m,
    act: state.dadoExtraido.act,
    v: state.dadoExtraido.v,
    or: state.dadoExtraido.or,
    [state.firstCustomField]: state.utmString
  }).forEach(([key, value]) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = key
    input.value = value
    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watch(
  () => state.html,
  (valor) => {
    state.textareaVazio = valor.trim() === ''
  }
)
</script>

<style scoped>
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.activecampaign {
  width: 100%;
}

button.abrir {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  width: 100%;
  transition: all 0.3s;
}

button.abrir.ativo {
  background-color: var(--cor-cinza-4);
}

button.abrir.ativo p {
  color: var(--cor-branco);
}

button.abrir:hover p {
  color: var(--cor-branco);
}

button.abrir svg {
  width: 15px;
  max-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

button.abrir p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.titulo button.fechar {
  background-color: transparent;
}

.titulo button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.titulo button.fechar:hover svg {
  fill: var(--cor-branco);
}

.balao.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.balao {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 20px;
  right: 290px;
  width: 100%;
  max-width: 400px;
  height: 60dvh;
  max-height: 60dvh;
  min-height: 60dvh;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 4;
  transition: all 0.3s;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60dvh;
  max-height: 60dvh;
  padding: 60px 20px 20px 20px;
  overflow-y: scroll;
}

.conteudo::-webkit-scrollbar {
  background-color: var(--cor-cinza-2);
}

.conteudo::-webkit-scrollbar-thumb {
  background-color: var(--cor-cinza-3);
}

.conteudo::-webkit-scrollbar-track {
  background-color: var(--cor-cinza-2);
}

.balao .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.balao .info p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
}

.balao .info a.tutorial {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-ciano);
  padding: 15px 0 20px 0;
  transition: all 0.3s;
}

.balao .info a.tutorial:hover {
  color: var(--cor-branco);
}

.balao .info a.tutorial:hover svg {
  fill: var(--cor-branco);
}

.balao .info a.tutorial svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-ciano);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.balao .info textarea {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-ciano);
  background: linear-gradient(40deg, var(--cor-cinza-3), var(--cor-cinza-2), var(--cor-cinza-3));
  padding: 15px;
  border-radius: 10px 10px 0 0;
  resize: none;
  line-height: 1.5;
  width: 100%;
  transition: all 0.3s;
}

.balao .info textarea:focus {
  background-color: var(--cor-cinza-4);
}

.balao .info textarea::placeholder {
  color: var(--cor-cinza-5);
}

.balao .info button.extrair:disabled {
  pointer-events: none;
  color: var(--cor-cinza-5);
}

.balao .info button.extrair {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  border-top: 2px solid var(--cor-cinza-4);
  padding: 15px;
  border-radius: 0 0 10px 10px;
  width: 100%;
  transition: all 0.3s;
}

.balao .info button.extrair:hover {
  background-color: var(--cor-cinza-4);
}

.balao .extraido {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0 0 0;
  animation: fadeIn 0.3s linear;
}

.balao .extraido p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 0 0 20px 0;
}

.balao .extraido form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid var(--cor-cinza-3);
  background: linear-gradient(40deg, var(--cor-cinza-3), var(--cor-cinza-2), var(--cor-cinza-3));
}

.balao .extraido form label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 0 0 10px 0;
}

.balao .extraido form input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--cor-cinza-4);
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

.balao .extraido form input:focus {
  border: 1px solid var(--cor-cinza-5);
}

.balao .extraido form button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0 0 0;
  border: 1px solid var(--cor-cinza-4);
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.balao .extraido form button:hover {
  background-color: var(--cor-cinza-4);
}

.balao .extraido button.salvar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 20px 15px;
  border-radius: 50px;
  background-color: var(--cor-verde);
  width: 100%;
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.balao .extraido button.salvar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.balao .extraido button.salvar:hover {
  background-color: var(--cor-azul);
}

/*.Responsivo */
@media screen and (max-width: 1024px) {
  .balao {
    right: 20px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: 55dvh;
    max-height: 55dvh;
    min-height: 55dvh;
  }

  .conteudo {
    height: 55dvh;
    max-height: 55dvh;
  }
}
</style>
