import { defineStore } from 'pinia'

export const useStoreSecao3 = defineStore('storeSecao3', {
  state: () => ({
    fundo: {
      id: 'fundoTopo',
      hidden: false,
      positionFixed: false,
      direction: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      backgroundColor: '0D0F1E',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      image: 'https://upload.unicpages.com/imagens/c0cd006d6103144dd0019c-background.png',
      padding: {
        top: 250,
        right: 80,
        bottom: 100,
        left: 80
      },
      gradienActive: false
    },
    fundoTexto: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    divisor: {
      id: '',
      hidden: true,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
    },
    imagemPrincipal: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/3f5c8300dc8c3bb1c92907-icone.svg',
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    iconeTag: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTag: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    titulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 60,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: 'D69D3A',
      textAlign: 'left',
      lineHeight: 120,
      maxWidth: 684,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem *ipsum dolor* sit amet, consectetur '
    },
    subtitulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 653,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum, nisl sapien dignissim erat, sed condimentum erat arcu at lectus. Nullam non dictum ex, id aliquet nunc. Vivamus sit amet orci dapibus, vestibulum nisl non, lacinia ligula. Suspendisse potenti.\n\n**Phasellus hendrerit**'
    },
    botao: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 600',
      fontSize: 17,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: 'D69D3A',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 70,
        bottom: 20,
        left: 70
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
