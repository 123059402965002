import { defineStore } from 'pinia'

export const useStoreTemplateProntoLinkBioMobile = defineStore('storeTemplateProntoLinkBioMobile', {
  state: () => ({
    // Seção Um > Topo1Mobile
    secaoUm: {
      tipo: 'section',
      id: 'id-gerado-aleatoriamente',
      modelo: 'Topo1Mobile',
      propriedades: {
        fundo: {
          id: '',
          hidden: false,
          positionFixed: false,
          direction: 'column-reverse',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '0D0F1E',
          gradientActive: true,
          gradientPrimary: '0A0A0A',
          gradientSecondary: 'CORESCURA',
          gradientDeg: 1,
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          padding: {
            top: 50,
            right: 30,
            bottom: 30,
            left: 30
          },
          gradientActive: false
        },
        fundoTexto: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        divisor: {
          id: '',
          hidden: true,
          image: 'https://upload.unicpages.com/imagens/c8c3bb1c92907d53bf41d7-divisor.png'
        },
        imagemPrincipal: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'FOTOAQUI',
          maxWidth: 159,
          margin: {
            top: 0,
            right: 0,
            bottom: 30,
            left: 0
          },
          borderRadius: {
            top: 200,
            right: 200,
            bottom: 200,
            left: 200
          }
        },
        iconeTag: {
          id: '',
          hidden: true,
          link: '',
          linkTarget: '_self',
          image: 'https://upload.unicpages.com/imagens/0dc8c3bb1c92907d53bf41-coroa-icone.svg',
          maxWidth: 20,
          margin: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        textoTag: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree, 600',
          fontSize: 18,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Lorem Ipsum'
        },
        titulo: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 600',
          fontSize: 30,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: 'CORDESTAQUE',
          textAlign: 'left',
          lineHeight: 120,
          maxWidth: 656,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'NOMEAQUI'
        },
        subtitulo: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree, 300',
          fontSize: 14,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 300,
          margin: {
            top: 10,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'DESCRICAO'
        },
        botao: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree, 600',
          fontSize: 17,
          color: 'FFFFFF',
          spanBackground: 'FFFFFF00',
          spanColor: 'FFFFFF',
          backgroundColor: '292C42',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 20,
            right: 70,
            bottom: 20,
            left: 70
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'Lorem ipsum',
          link: '',
          linkTarget: '_self',
          gradientActive: false
        }
      }
    },
    // Seção Dois > Quadro2Mobile
    secaoDois: {
      tipo: 'section',
      id: 'id-gerado-aleatoriamente',
      modelo: 'Quadro2Mobile',
      propriedades: {
        fundo: {
          id: '',
          hidden: false,
          positionFixed: false,
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '0A0A0A',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          padding: {
            top: 0,
            right: 30,
            bottom: 50,
            left: 30
          },
          gradientActive: false
        },
        fundoItens: {
          id: '',
          hidden: false,
          direction: 'row',
          alignItems: 'stretch',
          justifyContent: 'center',
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        divisor: {
          id: '',
          hidden: true,
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
        },
        fundoTitulo: {
          id: '',
          hidden: true,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        tituloIconeTag: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'https://upload.unicpages.com/imagens/957cad5838484d416289be-icone-tag.svg',
          maxWidth: 15,
          margin: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        tituloTextoTag: {
          id: '',
          hidden: false,
          letterSpacing: 10,
          fontFamily: 'Inter, 300',
          fontSize: 10,
          color: '010A16',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'LOREM IPSUM DOLOR'
        },
        tituloPrincipal: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 40,
          color: '010A16',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 1900,
          margin: {
            top: 10,
            right: 0,
            bottom: 30,
            left: 0
          },
          text: 'LOREM IPSUM AMET SIT'
        },
        tituloSubtitulo: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 22,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 1900,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
        },
        tituloBotao: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 20,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 50,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        fundoQuadroUm: {
          id: 'quadroInove',
          hidden: true,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'FFFFFF',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 50,
          margin: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 10,
            right: 0,
            bottom: 10,
            left: 0
          },
          gradientActive: false
        },
        iconeTagUm: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'https://arquivos.levelmember.com/imagens/web/icone-deg-crie.svg',
          maxWidth: 30,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        textoTagUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 30,
          color: '010A16',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 130,
          maxWidth: 600,
          margin: {
            top: 40,
            right: 0,
            bottom: 10,
            left: 0
          },
          text: 'LOREM\nIPSUM'
        },
        subtituloUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Inter, 300',
          fontSize: 15,
          color: '6D727B',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        botaoUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 16,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        fundoQuadroDois: {
          id: 'quadroInove',
          hidden: false,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'CORDESTAQUE00',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 50,
          margin: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          gradientActive: false
        },
        iconeTagDois: {
          id: '',
          hidden: false,
          link: 'WHATSAPP',
          linkTarget: '_blank',
          image: 'https://upload.unicpages.com/imagens/5e10e910da786dab136f35-whatsapp.png',
          maxWidth: 30,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        textoTagDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 30,
          color: '010A16',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 130,
          maxWidth: 600,
          margin: {
            top: 40,
            right: 0,
            bottom: 10,
            left: 0
          },
          text: 'LOREM\nIPSUM'
        },
        subtituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Inter, 300',
          fontSize: 15,
          color: '6D727B',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        botaoDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 16,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        fundoQuadroTres: {
          id: 'quadroInove',
          hidden: false,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'CORDESTAQUE00',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 50,
          margin: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          gradientActive: false
        },
        iconeTagTres: {
          id: '',
          hidden: false,
          link: 'TIKTOK',
          linkTarget: '_blank',
          image: 'https://upload.unicpages.com/imagens/55e10e910da786dab136f3-tiktok.png',
          maxWidth: 27,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        textoTagTres: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloTres: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 30,
          color: '010A16',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 130,
          maxWidth: 600,
          margin: {
            top: 40,
            right: 0,
            bottom: 10,
            left: 0
          },
          text: 'LOREM\nIPSUM'
        },
        subtituloTres: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Inter, 300',
          fontSize: 15,
          color: '6D727B',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        botaoTres: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 16,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        fundoQuadroQuatro: {
          id: 'quadroInove',
          hidden: false,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'CORDESTAQUE00',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 50,
          margin: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          gradientActive: false
        },
        iconeTagQuatro: {
          id: '',
          hidden: false,
          link: 'INSTAGRAM',
          linkTarget: '_blank',
          image: 'https://upload.unicpages.com/imagens/755e10e910da786dab136f-instagram.png',
          maxWidth: 30,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        textoTagQuatro: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloQuatro: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 30,
          color: '010A16',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 130,
          maxWidth: 600,
          margin: {
            top: 40,
            right: 0,
            bottom: 10,
            left: 0
          },
          text: 'LOREM\nIPSUM'
        },
        subtituloQuatro: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Inter, 300',
          fontSize: 15,
          color: '6D727B',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        botaoQuatro: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 16,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        }
      }
    },
    // Seção Tres Quadro1Mobile
    secaoTres: {
      tipo: 'section',
      id: 'id-gerado-aleatoriamente',
      modelo: 'Quadro1Mobile',
      propriedades: {
        fundo: {
          id: '',
          hidden: false,
          positionFixed: false,
          direction: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          backgroundColor: '0A0A0A',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          padding: {
            top: 0,
            right: 30,
            bottom: 20,
            left: 30
          },
          gradientActive: false
        },
        fundoQuadroUm: {
          id: '',
          hidden: false,
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: true,
          gradientPrimary: '191919',
          gradientSecondary: '353535',
          gradientDeg: 0,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 25,
            right: 25,
            bottom: 20,
            left: 25
          },
          gradientActive: true
        },
        fundoQuadroDois: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 25,
            bottom: 25,
            left: 25
          },
          gradientActive: false
        },
        divisor: {
          id: '',
          hidden: true,
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
        },
        iconeTagUm: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'FOTOAQUI',
          maxWidth: 30,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 200,
            right: 200,
            bottom: 200,
            left: 200
          }
        },
        iconeTagDois: {
          id: '',
          hidden: true,
          link: '',
          linkTarget: '_self',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
          maxWidth: 20,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          }
        },
        textoTagUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        textoTagDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        tituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        subtituloUm: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Inter, 700',
          fontSize: 15,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'OPCAOUMTEXTO'
        },
        subtituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 22,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
        },
        botaoUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 20,
          color: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        botaoDois: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 12,
          color: 'FFFFFF',
          backgroundColor: 'CORDESTAQUE',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'OPCAOUMBOTAO',
          link: '',
          linkTarget: '_self',
          gradientActive: false
        }
      }
    },
    // Seção Quatro > Quadro1Mobile
    secaoQuatro: {
      tipo: 'section',
      id: 'id-gerado-aleatoriamente',
      modelo: 'Quadro1Mobile',
      propriedades: {
        fundo: {
          id: '',
          hidden: false,
          positionFixed: false,
          direction: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          backgroundColor: '0A0A0A',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          padding: {
            top: 0,
            right: 30,
            bottom: 20,
            left: 30
          },
          gradientActive: false
        },
        fundoQuadroUm: {
          id: '',
          hidden: false,
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: true,
          gradientPrimary: '191919',
          gradientSecondary: '353535',
          gradientDeg: 0,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 25,
            right: 25,
            bottom: 20,
            left: 25
          },
          gradientActive: true
        },
        fundoQuadroDois: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 25,
            bottom: 25,
            left: 25
          },
          gradientActive: false
        },
        divisor: {
          id: '',
          hidden: true,
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
        },
        iconeTagUm: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'FOTOAQUI',
          maxWidth: 30,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 200,
            right: 200,
            bottom: 200,
            left: 200
          }
        },
        iconeTagDois: {
          id: '',
          hidden: true,
          link: '',
          linkTarget: '_self',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
          maxWidth: 20,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          }
        },
        textoTagUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        textoTagDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        tituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        subtituloUm: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Inter, 700',
          fontSize: 15,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'OPCAODOISTEXTO'
        },
        subtituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 22,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
        },
        botaoUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 20,
          color: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        botaoDois: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 12,
          color: 'FFFFFF',
          backgroundColor: 'CORDESTAQUE',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'OPCAODOISBOTAO',
          link: '',
          linkTarget: '_self',
          gradientActive: false
        }
      }
    },
    // seção cinco Quadro1Mobile
    secaoCinco: {
      tipo: 'section',
      id: 'id-gerado-aleatoriamente',
      modelo: 'Quadro1Mobile',
      propriedades: {
        fundo: {
          id: '',
          hidden: false,
          positionFixed: false,
          direction: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          backgroundColor: '0A0A0A',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          padding: {
            top: 0,
            right: 30,
            bottom: 20,
            left: 30
          },
          gradientActive: false
        },
        fundoQuadroUm: {
          id: '',
          hidden: false,
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: true,
          gradientPrimary: '191919',
          gradientSecondary: '353535',
          gradientDeg: 0,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 25,
            right: 25,
            bottom: 20,
            left: 25
          },
          gradientActive: true
        },
        fundoQuadroDois: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 25,
            bottom: 25,
            left: 25
          },
          gradientActive: false
        },
        divisor: {
          id: '',
          hidden: true,
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
        },
        iconeTagUm: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'FOTOAQUI',
          maxWidth: 30,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 200,
            right: 200,
            bottom: 200,
            left: 200
          }
        },
        iconeTagDois: {
          id: '',
          hidden: true,
          link: '',
          linkTarget: '_self',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
          maxWidth: 20,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          }
        },
        textoTagUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        textoTagDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        tituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        subtituloUm: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Inter, 700',
          fontSize: 15,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'OPCAOTRESTEXTO'
        },
        subtituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 22,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
        },
        botaoUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 20,
          color: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        botaoDois: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 12,
          color: 'FFFFFF',
          backgroundColor: 'CORDESTAQUE',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'OPCAOTRESBOTAO',
          link: '',
          linkTarget: '_self',
          gradientActive: false
        }
      }
    },
    // seção seis Quadro1Mobile
    secaoSeis: {
      tipo: 'section',
      id: 'id-gerado-aleatoriamente',
      modelo: 'Quadro1Mobile',
      propriedades: {
        fundo: {
          id: '',
          hidden: false,
          positionFixed: false,
          direction: 'column',
          alignItems: 'stretch',
          justifyContent: 'center',
          backgroundColor: '0A0A0A',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          padding: {
            top: 0,
            right: 30,
            bottom: 50,
            left: 30
          },
          gradientActive: false
        },
        fundoQuadroUm: {
          id: '',
          hidden: false,
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: true,
          gradientPrimary: '191919',
          gradientSecondary: '353535',
          gradientDeg: 0,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 25,
            right: 25,
            bottom: 20,
            left: 25
          },
          gradientActive: true
        },
        fundoQuadroDois: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 0,
            right: 0,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 25,
            bottom: 25,
            left: 25
          },
          gradientActive: false
        },
        divisor: {
          id: '',
          hidden: true,
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
        },
        iconeTagUm: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'FOTOAQUI',
          maxWidth: 30,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 200,
            right: 200,
            bottom: 200,
            left: 200
          }
        },
        iconeTagDois: {
          id: '',
          hidden: true,
          link: '',
          linkTarget: '_self',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
          maxWidth: 20,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          }
        },
        textoTagUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        textoTagDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        tituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 60,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        subtituloUm: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Inter, 700',
          fontSize: 15,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'OPCAOQUATROTEXTO'
        },
        subtituloDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 22,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
        },
        botaoUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 20,
          color: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        botaoDois: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 700',
          fontSize: 12,
          color: 'FFFFFF',
          backgroundColor: 'CORDESTAQUE',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'OPCAOQUATROBOTAO',
          link: '',
          linkTarget: '_self',
          gradientActive: false
        }
      }
    },
    // seção sete Quadro2Mobile
    secaoSete: {
      tipo: 'section',
      id: 'id-gerado-aleatoriamente',
      modelo: 'Quadro2Mobile',
      propriedades: {
        fundo: {
          id: 'secaoTemplates',
          hidden: false,
          positionFixed: false,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '0A0A0A',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          padding: {
            top: 0,
            right: 30,
            bottom: 60,
            left: 30
          },
          gradientActive: false
        },
        fundoItens: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        },
        divisor: {
          id: '',
          hidden: true,
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
        },
        fundoTitulo: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: {
            top: 0,
            right: 0,
            bottom: 30,
            left: 0
          }
        },
        tituloIconeTag: {
          id: '',
          hidden: true,
          link: '',
          linkTarget: '_self',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
          maxWidth: 20,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          }
        },
        tituloTextoTag: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloPrincipal: {
          id: 'tituloDegrade',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 600',
          fontSize: 20,
          color: 'FFFFFF',
          spanBackground: '00000000',
          spanColor: 'CORDESTAQUE',
          textAlign: 'center',
          lineHeight: 130,
          maxWidth: 320,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'TITULOCATALOGO'
        },
        tituloSubtitulo: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree, 300',
          fontSize: 15,
          color: 'FFFFFF87',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 320,
          margin: {
            top: 10,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'DESCRICAOCATALOGO'
        },
        tituloBotao: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 20,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 50,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self',
          gradientActive: false
        },
        fundoQuadroUm: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 320,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 25,
            left: 0
          },
          gradientActive: false
        },
        iconeTagUm: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_blank',
          image: 'FOTOAQUI',
          maxWidth: 1920,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 0,
            left: 0
          }
        },
        textoTagUm: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloUm: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 600',
          fontSize: 20,
          color: 'FFFFFF',
          spanBackground: 'CORDESTAQUE',
          spanColor: 'FFFFFF',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 25,
            right: 25,
            bottom: 0,
            left: 25
          },
          text: 'CATALOGOUMTITULO'
        },
        subtituloUm: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree, 300',
          fontSize: 14,
          color: '878794',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 25,
            bottom: 0,
            left: 25
          },
          text: 'CATALOGOUMDESCRICAO'
        },
        botaoUm: {
          id: 'botaoItem',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 500',
          fontSize: 14,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: 'CORDESTAQUE',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 10,
            right: 0,
            bottom: 0,
            left: 25
          },
          padding: {
            top: 12,
            right: 20,
            bottom: 12,
            left: 20
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'CATALOGOUMBOTAO',
          link: 'WHATSAPP',
          linkTarget: '_blank',
          gradientActive: false
        },
        fundoQuadroDois: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 320,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 25,
            left: 0
          },
          gradientActive: false
        },
        iconeTagDois: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_blank',
          image: 'FOTOAQUI',
          maxWidth: 1920,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 0,
            left: 0
          }
        },
        textoTagDois: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloDois: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 600',
          fontSize: 20,
          color: 'FFFFFF',
          spanBackground: 'CORDESTAQUE',
          spanColor: 'FFFFFF',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 25,
            right: 25,
            bottom: 0,
            left: 25
          },
          text: 'CATALOGODOISTITULO'
        },
        subtituloDois: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree, 300',
          fontSize: 14,
          color: '878794',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 25,
            bottom: 0,
            left: 25
          },
          text: 'CATALOGODOISDESCRICAO'
        },
        botaoDois: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 500',
          fontSize: 14,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: 'CORDESTAQUE',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 10,
            right: 0,
            bottom: 0,
            left: 25
          },
          padding: {
            top: 12,
            right: 20,
            bottom: 12,
            left: 20
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'CATALOGODOISBOTAO',
          link: 'WHATSAPP',
          linkTarget: '_blank',
          gradientActive: false
        },
        fundoQuadroTres: {
          id: '',
          hidden: false,
          direction: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          backgroundColor: '191919',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 320,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 25,
            left: 0
          },
          gradientActive: false
        },
        iconeTagTres: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_blank',
          image: 'FOTOAQUI',
          maxWidth: 1920,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 0,
            left: 0
          }
        },
        textoTagTres: {
          id: '',
          hidden: true,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloTres: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree, 700',
          fontSize: 20,
          color: 'FFFFFF',
          spanBackground: 'CORDESTAQUE',
          spanColor: 'FFFFFF',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 25,
            right: 25,
            bottom: 0,
            left: 25
          },
          text: 'CATALOGOTRESTITULO'
        },
        subtituloTres: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree, 300',
          fontSize: 14,
          color: '878794',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 25,
            bottom: 0,
            left: 25
          },
          text: 'CATALOGOTRESDESCRICAO'
        },
        botaoTres: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Alexandria, 500',
          fontSize: 14,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: 'CORDESTAQUE',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 10,
            right: 0,
            bottom: 0,
            left: 25
          },
          padding: {
            top: 12,
            right: 20,
            bottom: 12,
            left: 20
          },
          borderRadius: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10
          },
          text: 'CATALOGOTRESBOTAO',
          link: 'WHATSAPP',
          linkTarget: '_blank',
          gradientActive: false
        },
        fundoQuadroQuatro: {
          id: '',
          hidden: true,
          direction: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'FFFFFF',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          maxWidth: 400,
          margin: {
            top: 0,
            right: 10,
            bottom: 20,
            left: 10
          },
          borderRadius: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 20
          },
          padding: {
            top: 40,
            right: 40,
            bottom: 40,
            left: 40
          },
          gradientActive: false
        },
        iconeTagQuatro: {
          id: '',
          hidden: false,
          link: '',
          linkTarget: '_self',
          image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
          maxWidth: 15,
          margin: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          }
        },
        textoTagQuatro: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 15,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'left',
          lineHeight: 150,
          maxWidth: 500,
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          text: 'Site UnicPages'
        },
        tituloQuatro: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 30,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 600,
          margin: {
            top: 20,
            right: 0,
            bottom: 5,
            left: 0
          },
          text: 'Esse é o titulo para você preencher'
        },
        subtituloQuatro: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 18,
          color: '000000',
          spanBackground: '00000000',
          spanColor: '000000',
          textAlign: 'center',
          lineHeight: 150,
          maxWidth: 800,
          margin: {
            top: 0,
            right: 0,
            bottom: 20,
            left: 0
          },
          text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
        },
        botaoQuatro: {
          id: '',
          hidden: false,
          letterSpacing: 0,
          fontFamily: 'Figtree',
          fontSize: 16,
          color: 'ffffff',
          spanBackground: 'ffffff00',
          spanColor: 'ffffff',
          backgroundColor: '000000',
          gradientActive: false,
          gradientPrimary: 'ffffff00',
          gradientSecondary: 'ffffff00',
          gradientDeg: 1,
          textAlign: 'left',
          margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          },
          padding: {
            top: 15,
            right: 30,
            bottom: 15,
            left: 30
          },
          borderRadius: {
            top: 50,
            right: 50,
            bottom: 50,
            left: 50
          },
          text: 'Quero Unic',
          link: 'https://unicpages.com',
          linkTarget: '_self'
        }
      }
    }
  })
})
