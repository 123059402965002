import { defineStore } from 'pinia'

export const useStorePlano2Desktop = defineStore('storePlano2Desktop', {
  state: () => ({
    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 80,
        right: 80,
        bottom: 120,
        left: 80
      }
    },

    // Fundo
    fundoItens: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
    },

    // Quadro Um

    fundoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      }
    },

    nomeIconeQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    nomeTituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Plano Silver'
    },

    nomeSubtituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Essa é a descrição do plano'
    },

    precoUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '0% OFF'
    },

    precoDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '9'
    },

    precoQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },

    botaoUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    botaoDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '333333',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    listaTituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 30,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Benefícios do plano'
    },

    listaParteUmQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteUmQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteDoisQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteDoisQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteTresQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteTresQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteCincoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteCincoQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteSeisQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteSeisQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteSeisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteSeteQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteSeteQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteSeteQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    // Quadro Um

    fundoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      }
    },

    nomeIconeQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    nomeTituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Plano Silver'
    },

    nomeSubtituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Essa é a descrição do plano'
    },

    precoUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '0% OFF'
    },

    precoDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '9'
    },

    precoQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },

    botaoUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    botaoDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '333333',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    listaTituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 30,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Benefícios do plano'
    },

    listaParteUmQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteUmQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteDoisQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteDoisQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteTresQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteTresQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteCincoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteCincoQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteSeisQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteSeisQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteSeisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteSeteQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteSeteQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteSeteQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    // Quadro Um

    fundoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      }
    },

    nomeIconeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    nomeTituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Plano Silver'
    },

    nomeSubtituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Essa é a descrição do plano'
    },

    precoUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '0% OFF'
    },

    precoDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'R$'
    },

    precoTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 80,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 10
      },
      text: '9'
    },

    precoQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: ',00'
    },

    precoCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },

    botaoUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    botaoDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '333333',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },

    listaTituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 30,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Benefícios do plano'
    },

    listaParteUmQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteUmQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteDoisQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteDoisQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteTresQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteTresQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteCincoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteCincoQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteSeisQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteSeisQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteSeisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    },

    listaParteSeteQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      }
    },

    iconeParteSeteQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoParteSeteQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Benefício'
    }
  })
})
