import { defineStore } from 'pinia'

export const useStoreSecao9 = defineStore('storeSecao9', {
  state: () => ({
    fundo: {
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'F5F5F7',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 40,
        right: 80,
        bottom: 80,
        left: 80
      },
      gradienActive: false
    },
    fundoQuadroUm: {
      hidden: false,
      direction: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      backgroundColor: 'ffffff00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 383,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    fundoQuadroDois: {
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 429,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 60
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    divisor: {
      hidden: true,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
    },
    iconeTagUm: {
      hidden: false,
      image: 'https://upload.unicpages.com/imagens/9f1e336b755e10e910da78-Ativo 1.png',
      maxWidth: 478,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    iconeTagDois: {
      hidden: true,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagUm: {
      hidden: true,
      fontFamily: 'figtree-light',
      fontSize: 18,
      color: '000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    textoTagDois: {
      hidden: false,
      fontFamily: 'figtree-bold',
      fontSize: 14,
      color: 'A031DD',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem Ipsum'
    },
    tituloUm: {
      hidden: true,
      fontFamily: 'figtree-light',
      fontSize: 60,
      color: '000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },
    tituloDois: {
      hidden: false,
      fontFamily: 'filson-pro-medium',
      fontSize: 21,
      color: '1E61DE',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 10,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    subtituloUm: {
      hidden: true,
      fontFamily: 'figtree-light',
      fontSize: 22,
      color: '000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
    },
    subtituloDois: {
      hidden: false,
      fontFamily: 'figtree-light',
      fontSize: 14,
      color: '000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 429,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Phasellus sit amet nunc non lorem pharetra auctor nec at nunc. Nulla facilisi. Nam porttitor, velit id sodales feugiat, lacus nulla facilisis elit, sit amet laoreet arcu felis vitae erat. Proin id orci ac libero mollis aliquet.'
    },
    botaoUm: {
      hidden: true,
      fontFamily: 'figtree-light',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoDois: {
      hidden: false,
      fontFamily: 'figtree-bold',
      fontSize: 16,
      color: 'FFFFFF',
      backgroundColor: 'DD3E90',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Lorem Ipsum',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
