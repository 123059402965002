import { defineStore } from 'pinia'

export const useStoreSecao13 = defineStore('storeSecao13', {
  state: () => ({
    fundo: {
      hidden: false,
      positionFixed: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '0C0C0C',
      image: 'https://upload.unicpages.com/imagens/336b755e10e910da786dab-tato.png',
      gradientActive: true,
      gradientPrimary: '0C0C0C',
      gradientSecondary: '0C0C0C88',
      gradientDeg: 1,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: true
    },
    fundoTexto: {
      hidden: true,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    divisor: {
      hidden: true,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
    },
    iconeTag: {
      hidden: false,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTag: {
      hidden: false,
      fontFamily: 'figtree-light',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    titulo: {
      hidden: false,
      fontFamily: 'figtree-light',
      fontSize: 60,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },
    subtitulo: {
      hidden: false,
      fontFamily: 'figtree-light',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },
    botao: {
      hidden: false,
      fontFamily: 'figtree-light',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },
    fundoForm: {
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'ffffff00',
      maxWidth: 480,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 40,
        right: 0,
        bottom: 80,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeTagForm: {
      hidden: true,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoTagForm: {
      hidden: true,
      fontFamily: 'figtree-light',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 300,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloForm: {
      hidden: false,
      fontFamily: 'Karantina, 700',
      fontSize: 58,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 136,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'LOREM IPSUM'
    },
    subtituloForm: {
      hidden: false,
      fontFamily: 'figtree-light',
      fontSize: 17,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 159,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Have you ever thought about getting an amazing tattoo that is truly unique and represents your personality and style?'
    },
    botaoForm: {
      hidden: false,
      fontFamily: 'Figtree, 700',
      fontSize: 20,
      color: 'FFFFFF',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: true,
      gradientPrimary: '0D91FF',
      gradientSecondary: '1C5BFF',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 50,
        bottom: 20,
        left: 50
      },
      borderRadius: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5
      },
      text: 'Lorem ipsum',
      link: 'https://discord.com/api/webhooks/1247348276592902185/jFYvVUwh9oI0UG647Wus7Oa_Zr4jphir1gBC0R59ixPG9wodjoT66SpIAvVpGGtM7YE0',
      linkTarget: '_self',
      gradienActive: true
    },
    labelUm: {
      hidden: false,
      fontFamily: 'Figtree, 600',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: 'FF5454',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem Ipsum ***'
    },
    inputUm: {
      hidden: false,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'FFFFFF',
      backgroundColor: 'FFFFFF1A',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      borderRadius: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5
      },
      text: 'Lorem Ipsum'
    },
    labelDois: {
      hidden: false,
      fontFamily: 'Figtree, 600',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: 'FF5454',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem Ipsum ***'
    },
    inputDois: {
      hidden: false,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'FFFFFF',
      backgroundColor: 'FFFFFF1A',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      borderRadius: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5
      },
      text: 'Lorem Ipsum'
    },
    labelTres: {
      hidden: false,
      fontFamily: 'Figtree, 600',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: 'FF5454',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem Ipsum ***'
    },
    inputTres: {
      hidden: false,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'FFFFFF',
      backgroundColor: 'FFFFFF1A',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      borderRadius: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5
      },
      text: 'Lorem Ipsum'
    },
    modal: {
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '0C0C0C',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 80,
        bottom: 100,
        left: 80
      },
      gradienActive: false
    },
    fundoTextoModal: {
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    iconeTagModal: {
      hidden: true,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagModal: {
      hidden: true,
      fontFamily: 'figtree-light',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloModal: {
      hidden: false,
      fontFamily: 'karantina-regular',
      fontSize: 99,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 109,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'FINISH'
    },
    subtituloModal: {
      hidden: false,
      fontFamily: 'figtree-light',
      fontSize: 22,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 341,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'The form has been filled out and we will be in touch shortly.'
    },
    botaoModal: {
      hidden: false,
      fontFamily: 'figtree-bold',
      fontSize: 18,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: true,
      gradientPrimary: '8A6337',
      gradientSecondary: 'BE8F58',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 50,
        bottom: 20,
        left: 50
      },
      borderRadius: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5
      },
      text: 'Our Instagram',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: true
    }
  })
})
