<template>
  <Modal nome="criarComTemplate">
    <div class="conteudo">
      <div class="template">
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="`background-image: url('${state.template?.imagemCapa}');`"></div>
          </div>
        </div>
        <div class="texto">
          <h3>{{ categoriasMapeadas[state.template?.categoria] || state.template?.categoria }}</h3>
          <p>{{ state.template?.nome }}</p>
        </div>
      </div>

      <label class="um">
        Nome do site
        <span>*</span>
      </label>
      <input v-model="state.nomeDoSite" type="text" autocomplete="off" spellcheck="false" placeholder="Ex: Página de Vendas" @keydown.enter="criarSite" />

      <label>
        Descrição do site
        <span>*</span>
      </label>
      <textarea v-model="state.descricaoDoSite" autocomplete="off" spellcheck="false" placeholder="Ex: Foco em vendas de produtos" @keydown.enter="criarSite"></textarea>

      <label>
        URL do seu site
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>unic.page/</p>
        <input v-model="state.hotlinkSite" @input="validarHotlink()" autocomplete="off" spellcheck="false" type="text" placeholder="Digite o hotlink" @keydown.enter="criarSite" />
      </div>

      <label>Imagem de capa (opcional)</label>
      <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
        <span></span>
        <div class="previa" :style="`background-image: url('${state.fotoCapa || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/capa-site-unic.png'}')`">
          <Svgs v-if="!state.fotoCapa" nome="arquivo" />
        </div>
        <div class="texto">
          <h3>{{ state.nomeArquivo || 'Subir Capa' }}</h3>
          <p>{{ state.tamanhoArquivo || 'Tamanho recomendado: 800x160' }}</p>
        </div>
        <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
      </div>

      <BotaoSalvar nome="Criar com template" @click="criarSite" />
    </div>
  </Modal>
</template>
<script setup>
import { inject, onMounted, reactive, ref, watch, computed } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import ApiUpload from '@/api/upload/api-upload.js'

const emitter = inject('emitter')
const storePages = useStorePages()
const storeModal = useStoreModal()
const inputFile = ref(null)
const dadosModal = computed(() => storeModal.dadosDoModal('criarComTemplate'))

const state = reactive({
  nomeDoSite: '',
  descricaoDoSite: '',
  hotlinkSite: '',
  fotoCapa: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  loading: false
})

const categoriasMapeadas = {
  'academia': 'Academia',
  'academia-de-luta': 'Academia de Luta',
  'acai': 'Açaí',
  'acougue': 'Açougue',
  'assistencia-tecnica': 'Assistência Técnica',
  'cabeleireiro': 'Cabeleireiro',
  'consultorio-medico': 'Consultório Médico',
  'contabilidade': 'Contabilidade',
  'conveniencia': 'Conveniência',
  'dentista': 'Dentista',
  'escola-de-idiomas': 'Escola de Idiomas',
  'escola-e-faculdade': 'Escola e Faculdade',
  'escritorio-advocacia': 'Escritório Advocacia',
  'estetica-automotiva': 'Estética Automotiva',
  'farmacia': 'Farmácia',
  'hamburgueria': 'Hamburgueria',
  'imobiliaria': 'Imobiliária',
  'link-de-bio': 'Link de Bio',
  'loja-de-moveis': 'Loja de Móveis',
  'loja-de-roupa': 'Loja de Roupa',
  'loja-de-tintas': 'Loja de Tintas',
  'maquiadora': 'Maquiadora',
  'marketing-digital': 'Marketing Digital',
  'materiais-de-construcao': 'Materiais de Construção',
  'mercearia': 'Mercearia',
  'mecanica': 'Mecânica',
  'padaria': 'Padaria',
  'papelaria': 'Papelaria',
  'pet-shop': 'Pet Shop',
  'portfolio': 'Portfólio',
  'sorveteria': 'Sorveteria'
}

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 800
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.fotoCapa = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de capa' })
    })
}

function validarHotlink() {
  let hotlink = state.hotlinkSite

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlinkSite = hotlink
}

async function criarSite() {
  if (!validarDados()) return

  const payload = {
    nome: state.nomeDoSite,
    descricao: state.descricaoDoSite,
    cor: 'FFFFFF',
    imagemCapa: state.fotoCapa,
    hotlink: state.hotlinkSite?.trim(),
    hotlinkTemplate: state.template?.hotlink,
    online: true,
    metatags: {
      titulo: state.nomeDoSite,
      descricao: state.descricaoDoSite,
      favicon: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/favicons/favicon.icohttps://central-statics.nyc3.digitaloceanspaces.com/unicpages/favicons/favicon.png'
    }
  }

  const adicionadoOk = await storePages.clonarTemplate(payload)
  if (adicionadoOk !== true) return

  storeModal.fecharModal('criarComTemplate')
  setTimeout(() => {
    window.open(`/criar/${state.hotlinkSite}`, '_self')
  }, 500)
}

function validarDados() {
  let mensagem
  if (!state.nomeDoSite || !state.descricaoDoSite || !state.hotlinkSite) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.template = ''
  state.nomeDoSite = ''
  state.descricaoDoSite = ''
  state.fotoCapa = ''
  state.hotlinkSite = ''
  state.hotlinkTemplate = ''
}

async function carregarTemplate() {
  if (dadosModal.value.id) {
    state.template = dadosModal.value.id
    state.fotoCapa = state.template?.imagemCapa
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        carregarTemplate()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.template {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 20px 0;
}

.template .capa {
  width: 100%;
  max-width: 150px;
}

.template .capa .tamanho {
  position: relative;
  padding-bottom: 75%;
  width: 100%;
}

.template .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-preto);
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.template .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
}

.template .texto h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.template .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 10px 0 0 0;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .template {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 20px 0;
  }

  .template .capa {
    max-width: 100%;
  }

  .template .texto {
    padding: 20px 0 0 0;
  }
}
</style>
