<template>
  <Modal nome="replicarMobile">
    <img src="https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/replicar-mobile.svg" />

    <div class="info">
      <h3>
        <Svgs nome="atencao" />
        Versão mobile atual será reescrita
      </h3>
      <p>Se você tiver dados ou conteúdo no modo celular, eles serão excluídos ao selecionar esta opção. A nova cópia substituirá o que foi perdido, e não será possível recuperar os dados anteriores.</p>
    </div>

    <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
      <span>
        <Svgs nome="check" />
      </span>
      <p>Sim, desejo replicar para mobile</p>
    </div>

    <BotaoSalvar nome="Replicar para Mobile" @click="replicarParaMobile" :disabled="state.desabilitarBotao" />
  </Modal>
</template>

<script setup>
import { reactive, inject } from 'vue'
import { useStoreAjustes, useStoreModal, useStoreCriar } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const emitter = inject('emitter')
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()
const storeModal = useStoreModal()

const state = reactive({
  confirmar: false,
  desabilitarBotao: false
})

function replicarParaMobile() {
  if (!state.confirmar) {
    const mensagem = 'Marque a caixinha para replicar'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return
  }

  state.desabilitarBotao = true
  storeCriar.mostrarLoading = false
  storeAjustes.replicarParaMobile()
  storeCriar.replicarParaMobile()

  emitter.emit('renderizarComponentesTela')
  emitter.emit('ocultarAjustes')

  setTimeout(() => {
    storeModal.fecharModal('replicarMobile')
    window.location.reload()
  }, 2000)
}
</script>

<style scoped>
img {
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0 0 0;
}
.info svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-azul);
  margin: 0 10px 0 0;
}
.info h3 {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 0 0 5px 0;
}
.info p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}
.inputAtivacao {
  margin: 20px 0 20px 0;
}
/* Responsivo */
@media screen and (max-width: 1024px) {
  .titulo {
    padding: 20px;
  }
  .botao {
    padding: 20px;
  }
  .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
