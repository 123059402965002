import { defineStore } from 'pinia'

export const useStoreFormulario3Mobile = defineStore('storeFormulario3Mobile', {
  state: () => ({
    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 60,
        right: 30,
        bottom: 80,
        left: 30
      }
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/divisor-1.png'
    },

    // Video
    video: {
      id: '',
      hidden: false,
      url: 'https://www.youtube.com/embed/zFAFXBlSsTU?si=XuMcnMIK1glpnADA',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      maxWidth: 350,
      maxHeight: 180,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      }
    },

    // Formulário
    fundoForm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      maxWidth: 350,
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 40,
        right: 30,
        bottom: 40,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      }
    },

    iconeTagForm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    textoTagForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 300,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },

    tituloForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Título do form integrado ao Discord'
    },

    subtituloForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para esse formulário integrado com o Discord para receber os dados em um canal'
    },

    botaoForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'Link webhook discord aqui',
      linkTarget: '_self'
    },

    labelUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 13,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Qual seu nome?'
    },

    inputUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui'
    },

    labelDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 13,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Qual seu whatsapp?'
    },

    inputDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui'
    },

    labelTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 13,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Qual seu e-mail?'
    },

    inputTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui'
    },

    labelQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 13,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Qual seu e-mail?'
    },

    select: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
      opcoesSelect: 'Opção 1, Opção 2, Opção 3'
    },

    labelCinco: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 13,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Qual seu e-mail?'
    },

    textarea: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 550,
      maxHeight: 150,
      lineHeight: 150,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui'
    },

    // Modal
    modal: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 30,
        bottom: 100,
        left: 30
      }
    },

    fundoTextoModal: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },

    iconeTagModal: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },

    textoTagModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },

    tituloModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Essa é a página de obrigado'
    },

    subtituloModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição da página de obrigado. As pessoas vão cair nessa tela assim que o envio do formulário for feito.'
    },

    botaoModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    }
  })
})
