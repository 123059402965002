<template>
  <section class="lista">
    <div class="conteudo zero" v-if="dadosCarregados && storePages.pages?.length === 0">
      <div class="icone">
        <Svgs nome="cursor" />
      </div>
      <h2>Você não tem nenhum site criado</h2>
      <p>Chegou a hora de criar seu site usando a tecnologia Unic, clique abaixo para começar:</p>
      <div class="botoes">
        <button @click="escolherCriar()">
          <Svgs nome="mais" />
          <h3>Criar site</h3>
          <h3>do zero</h3>
        </button>
        <button @click="escolherTemplates()">
          <Svgs nome="template" />
          <h3>Criar com</h3>
          <h3>template</h3>
        </button>
      </div>
    </div>

    <div class="conteudo" v-else-if="dadosCarregados">
      <div class="busca">
        <div class="input">
          <Svgs class="icone" nome="lupa" />
          <input type="text" placeholder="Buscar site..." autocomplete="off" spellcheck="false" v-model="searchQuery" />
        </div>
        <div class="filtrar">
          <Svgs nome="setinha" />
          <select v-model="selectedFilter">
            <option value="todos">Todos</option>
            <option value="alfabetica-asc">Alfabética A-Z</option>
            <option value="alfabetica-desc">Alfabética Z-A</option>
            <option value="mais-antigos">Mais antigos</option>
            <option value="mais-novos">Mais novos</option>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
          </select>
        </div>
        <button class="reorganizar" @click="abrirModal('posicoes', 'posicao', 'Reorganizar sites')">
          <Svgs nome="posicao" />
          <p>Reorganizar</p>
        </button>
      </div>
      <div class="itens">
        <div class="item" v-for="page in paginasFiltradas" :key="page._id">
          <div class="capa">
            <div class="tamanho">
              <div class="imagem" :style="`background-image: url('${page.imagemCapa || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/capa-site-unic.png'}')`">
                <div class="normal" :style="`background-image: url('${page.imagemCapa || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/capa-site-unic.png'}')`"></div>
              </div>
            </div>
          </div>
          <div class="info">
            <div class="nome">
              <h3>{{ page?.nome }}</h3>
              <p>{{ page?.descricao.length > 60 ? page.descricao.substring(0, 60) + '...' : page.descricao }}</p>
            </div>
          </div>
          <div class="links">
            <a class="dominio" target="_blank" @click="irParaDominio(page)" :class="{ sem: !page?.dominioPersonalizado }" v-if="page?.dominioPersonalizado">{{ page?.dominioPersonalizado || '' }}{{ page?.dominioRota ? '/' + page?.dominioRota : '' || '' }}</a>
            <a class="dominio sem" v-if="!page?.dominioPersonalizado">Domínio não configurado</a>
            <a class="hotlink" target="_blank" @click="irParaHotlink(page)">unic.page/{{ page?.hotlink }}</a>
          </div>
          <div class="botoes">
            <div class="status" :class="page?.online ? 'on' : 'off'">
              <span></span>
              <h3>{{ page?.online ? 'Online' : 'Offline' }}</h3>
            </div>
            <button class="icone duplicar" @click="abrirModalEdicao('duplicarSite', 'duplicar', 'Duplicar site', page?._id)">
              <Svgs nome="duplicar" />
            </button>
            <button class="icone excluir" @click="abrirModalEdicao('excluirSite', 'excluir', 'Excluir site', page?._id)">
              <Svgs nome="excluir" />
            </button>
            <button class="editar" @click="editarSite(page?.hotlink)">
              <Svgs nome="editar" />
              <p>Editar site</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStorePages, useStoreModal } from '@stores'
import Svgs from '@svgs'

const storePages = useStorePages()
const storeModal = useStoreModal()
const router = useRouter()

const searchQuery = ref('')
const selectedFilter = ref('todos')
const dadosCarregados = ref(false)

const paginasFiltradas = computed(() => {
  let pages = storePages.pages || []

  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase()
    pages = pages.filter((page) => {
      return page.nome?.toLowerCase().includes(query) || page.descricao?.toLowerCase().includes(query) || page.hotlink?.toLowerCase().includes(query) || page.dominioPersonalizado?.toLowerCase().includes(query)
    })
  }

  switch (selectedFilter.value) {
    case 'alfabetica-asc':
      pages = pages.slice().sort((a, b) => a.nome.localeCompare(b.nome))
      break
    case 'alfabetica-desc':
      pages = pages.slice().sort((a, b) => b.nome.localeCompare(a.nome))
      break
    case 'mais-antigos':
      pages = pages.slice().sort((a, b) => new Date(a.dataRegistro) - new Date(b.dataRegistro))
      break
    case 'mais-novos':
      pages = pages.slice().sort((a, b) => new Date(b.dataRegistro) - new Date(a.dataRegistro))
      break
    case 'online':
      pages = pages.filter((page) => page.online)
      break
    case 'offline':
      pages = pages.filter((page) => !page.online)
      break
  }

  return pages
})

function escolherTemplates() {
  storeModal.fecharModal('opcoesCriacao')
  router.push('/templates')
}

function escolherCriar() {
  storeModal.abrirModal('criarDoZero', 'cursor', 'Criar site do zero')
  storeModal.fecharModal('opcoesCriacao')
}

function abrirModal(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
}

function abrirModalEdicao(nome, icone, titulo, id) {
  storeModal.abrirModalEdicao(nome, icone, titulo, id)
}

function editarSite(hotlink) {
  window.location.href = `/criar/${hotlink}`
}

function irParaHotlink(page) {
  const url = `https://unic.page/${page?.hotlink}`

  window.open(url, '_blank')
}

function irParaDominio(page) {
  const url = page.dominioRota ? `https://${page?.dominioPersonalizado}/${page?.dominioRota}` : `https://${page?.dominioPersonalizado}`

  window.open(url, '_blank')
}

watch(
  () => storePages.pages,
  (newPages) => {
    dadosCarregados.value = !!newPages && newPages.length >= 0
  },
  { immediate: true }
)
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 5px 0 20px;
  min-height: calc(100dvh - 270px);
  position: relative;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  animation: fadeIn 0.3s linear;
}

.conteudo.zero {
  padding: 80px 20px 30px 20px;
}

.conteudo.zero .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
}

.conteudo.zero .icone svg {
  width: 40px;
  min-width: 40px;
  fill: var(--cor-branco);
}

.conteudo.zero h2 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
}

.conteudo.zero p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.conteudo.zero .botoes {
  display: flex;
  margin: 20px 0 0 0;
}

.conteudo.zero .botoes button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  border: 2px solid var(--cor-cinza-3);
  border-radius: 10px;
  padding: 35px 0;
  width: 150px;
  margin: 0 5px;
  transition: all 0.3s;
}

.conteudo.zero .botoes button:hover {
  border: 2px solid var(--cor-azul);
}

.conteudo.zero .botoes button svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-azul);
  margin: 0 0 10px 0;
}

.conteudo.zero .botoes button h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px 20px 0;
}

.busca .input {
  width: calc(70% - 10px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(15% - 10px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.busca .reorganizar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  width: calc(15% - 10px);
  background-color: var(--cor-cinza-2);
  transition: all 0.3s;
}

.busca .reorganizar:hover {
  background-color: var(--cor-cinza-3);
}

.busca .reorganizar svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.busca .reorganizar p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(33.33% - 15px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin: 0 15px 20px 0;
}

.item .capa {
  width: 100%;
}

.item .capa .tamanho {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20%;
  overflow: hidden;
}

.item .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px 10px 0 0;
  background-size: cover;
  background-position: center;
}

.item .capa .tamanho .imagem .normal {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(20px);
  border-radius: 10px 10px 0 0;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 0;
}

.item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.item .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.item .status {
  display: flex;
  align-items: center;
  background-color: var(--cor-cinza-3);
  padding: 10px 15px;
  border-radius: 50px;
  margin-right: auto;
}

.item .status span {
  width: 5px;
  min-width: 5px;
  height: 5px;
  min-height: 5px;
  border-radius: 50%;
  margin: 0 5px 0 0;
}

.item .status h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.item .status.on span {
  background-color: var(--cor-azul);
}

.item .status.off span {
  background-color: var(--cor-vermelho);
}

.item .links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .links a.dominio {
  color: var(--cor-verde);
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .links a {
  text-align: center;
  width: 100%;
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  word-break: keep-all;
  overflow-x: scroll;
  padding: 10px;
  line-height: 1.3;
  transition: all 0.3s;
  scrollbar-width: none;
}

.item .links a::-webkit-scrollbar {
  display: none;
}

.item .links a.sem {
  color: var(--cor-laranja);
  pointer-events: none;
}

.item .links a:hover {
  color: var(--cor-azul);
}

.item .botoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.item .botoes button.icone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 35px;
  height: 35px;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.item .botoes button.icone svg {
  width: 17px;
  min-width: 17px;
  max-height: 17px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.item .botoes button.icone.excluir:hover {
  background-color: var(--cor-vermelho);
}

.item .botoes button.icone.excluir:hover svg {
  fill: var(--cor-branco-fixo);
}

.item .botoes button.icone.duplicar:hover {
  background-color: var(--cor-laranja);
}

.item .botoes button.icone.excluir:hover svg {
  fill: var(--cor-branco-fixo);
}

.item .botoes button.editar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul);
  padding: 8px 12px;
  border-radius: 10px;
  transition: all 0.3s;
}

.item .botoes button.editar:hover {
  background-color: var(--cor-azul-escuro);
}

.item .botoes button.editar p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

.item .botoes button.editar svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco-fixo);
  margin: 0 8px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.lista {
    padding: 20px 20px 0 20px;
  }

  .conteudo.zero {
    padding: 40px 0 0 0;
  }

  .conteudo.zero .icone {
    width: 60px;
    height: 60px;
  }

  .conteudo.zero .icone svg {
    width: 30px;
    min-width: 30px;
  }

  .conteudo.zero h2 {
    font-size: var(--f3);
    text-align: center;
    margin: 20px 0 5px 0;
  }

  .conteudo.zero p {
    line-height: 1.5;
    text-align: center;
  }

  .conteudo.zero .botoes {
    display: flex;
    margin: 20px 0 0 0;
  }

  .conteudo.zero .botoes button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--cor-cinza-2);
    border: 2px solid var(--cor-cinza-3);
    border-radius: 10px;
    padding: 35px 0;
    width: 150px;
    margin: 0 5px;
    transition: all 0.3s;
  }

  .conteudo.zero .botoes button:hover {
    border: 2px solid var(--cor-azul);
  }

  .conteudo.zero .botoes button svg {
    width: 20px;
    min-width: 20px;
    max-height: 20px;
    fill: var(--cor-azul);
    margin: 0 0 10px 0;
  }

  .conteudo.zero .botoes button h3 {
    font-family: var(--bold);
    font-size: var(--f2);
    color: var(--cor-branco);
    margin: 5px 0 0 0;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(50% - 10px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 10px);
  }

  .busca .reorganizar {
    padding: 12px 0;
    width: calc(15% - 10px);
  }

  .busca .reorganizar svg {
    width: 20px;
    min-width: 20px;
    margin: 0;
  }

  .busca .reorganizar p {
    display: none;
  }

  .item {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .item .info {
    width: 100%;
    padding: 20px;
  }

  .item .foto {
    width: 40px;
    height: 40px;
  }

  .item .nome {
    padding: 0 20px 0 0;
  }

  .item .nome h3 {
    font-size: var(--f1);
  }

  .item .nome p {
    font-size: var(--f0);
  }

  .item .status {
    padding: 8px 12px;
  }

  .item .status h3 {
    font-size: var(--f0);
  }

  .item .links a {
    width: 100%;
    font-size: var(--f0);
  }

  .item .botoes {
    padding: 20px;
  }

  .item .botoes button.editar p {
    font-size: var(--f1);
  }

  .item .botoes button.editar svg {
    width: 10px;
    min-width: 10px;
    margin: 0 8px 0 0;
  }
}
</style>
