import { defineStore } from 'pinia'

export const useStoreSecao22 = defineStore('storeSecao22', {
  state: () => ({
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'EFF1F7',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 80,
        right: 80,
        bottom: 80,
        left: 80
      },
      gradienActive: false
    },
    fundoItens: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'stretch',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    fundoToggle: {
      id: 'toggleDesktop',
      hidden: true,
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 90,
      maxHeight: 45,
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      gradienActive: false
    },
    spanToggle: {
      id: '',
      hidden: false,
      backgroundColor: '4476FF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 45,
      maxHeight: 45,
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      gradienActive: false
    },
    divisor: {
      id: '',
      hidden: true,
      image: 'https://arquivos.levelmember.com/imagens/web/divisor-4.png'
    },
    iconeToggleUm: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoToggleUm: {
      id: 'mensalPlano',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 300,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Mensal'
    },
    iconeToggleDois: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoToggleDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 18,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '4476FF',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Anual '
    },
    fundoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    nomeIconeQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.levelmember.com/imagens/web/plano-essencial.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    nomeTituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 20,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 15
      },
      text: 'LOREM'
    },
    precoUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 40,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 197'
    },
    precoDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'cobrado mensalmente'
    },
    precoQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 16,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod'
    },
    precoCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 40,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 164'
    },
    precoSeisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoSeteQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado Anualmente'
    },
    precoOitoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 16,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 1.970 à vista'
    },
    botaoUmQuadroUm: {
      id: 'botaoPlanos',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'E4CD5B',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'LOREM IPSUM',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    botaoDoisQuadroUm: {
      id: 'botaoPlanos',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: 'E4CD5B',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 40,
        bottom: 20,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'ASSINAR ESSENCIAL',
      link: 'https://pay.hotmart.com/P94506149E?off=p0n7xekc&checkoutMode=10',
      linkTarget: '_blank',
      gradienActive: false
    },
    listaParteUmQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteUmQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/c217bcc2efcf89ea0d1b64-icone-1-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteDoisQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDoisQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/217bcc2efcf89ea0d1b645-icone-2-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteTresQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteTresQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/17bcc2efcf89ea0d1b6452-icone-3-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteQuatroQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/7bcc2efcf89ea0d1b64521-icone-4-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteCincoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteCincoQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/bcc2efcf89ea0d1b645213-icone-5-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteSeisQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeisQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/cc2efcf89ea0d1b6452132-icone-6-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteSeisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteSeteQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeteQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/c2efcf89ea0d1b64521325-icone-7-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteSeteQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteOitoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOitoQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/2efcf89ea0d1b64521325a-icone-8-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteOitoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteNoveQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteNoveQuadroUm: {
      id: 'iconePlanoEssencial',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/efcf89ea0d1b64521325a6-icone-9-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteNoveQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteDezQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDezQuadroUm: {
      id: 'iconePlanoOff',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/fcf89ea0d1b64521325a60-icone-10-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteDezQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor',
      textDecoration: 'line-through'
    },
    listaParteOnzeQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOnzeQuadroUm: {
      id: 'iconePlanoOff',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/cf89ea0d1b64521325a606-ico-11-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteOnzeQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor',
      textDecoration: 'line-through'
    },
    listaParteDozeQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDozeQuadroUm: {
      id: 'iconePlanoOff',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/f89ea0d1b64521325a606e-ico-12-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoParteDozeQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor',
      textDecoration: 'line-through'
    },
    fundoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 20
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    nomeIconeQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.levelmember.com/imagens/web/plano-pro.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    nomeTituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 20,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 15
      },
      text: 'IPSUM'
    },
    precoUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 40,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 497'
    },
    precoDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'cobrado mensalmente'
    },
    precoQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 16,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod'
    },
    precoCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 40,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 414'
    },
    precoSeisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoSeteQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado Anualmente'
    },
    precoOitoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 16,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 4.970 à vista'
    },
    botaoUmQuadroDois: {
      id: 'botaoPlanos',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: 'C070F5',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'LOREM IPSUM',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    botaoDoisQuadroDois: {
      id: 'botaoPlanos',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: 'C070F5',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'ASSINAR PRO',
      link: 'https://pay.hotmart.com/P94506149E?off=gv5wo20g&checkoutMode=10',
      linkTarget: '_blank',
      gradienActive: false
    },
    listaParteUmQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteUmQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/a0d1b64521325a606ee883-icone-1-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteDoisQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDoisQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/4521325a606ee883ed8953-icone-2-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteTresQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteTresQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/521325a606ee883ed89533-icone-3-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/21325a606ee883ed895335-icone-4-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteCincoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteCincoQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/1325a606ee883ed895335a-icone-5-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteSeisQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeisQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/325a606ee883ed895335a3-icone-6-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteSeteQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeteQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/25a606ee883ed895335a35-icone-7-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeteQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteOitoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOitoQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/5a606ee883ed895335a359-icone-8-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOitoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteNoveQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteNoveQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/06ee883ed895335a35932e-icone-9-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteNoveQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteDezQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDezQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/6ee883ed895335a35932e5-icone-10-ess.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDezQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteOnzeQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOnzeQuadroDois: {
      id: 'iconePlanoOff',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/ee883ed895335a35932e53-ico-11-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOnzeQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor',
      textDecoration: 'line-through'
    },
    listaParteDozeQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDozeQuadroDois: {
      id: 'iconePlanoOff',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/e883ed895335a35932e53e-ico-12-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDozeQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor',
      textDecoration: 'line-through'
    },
    fundoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      backgroundColor: 'FFFFFF',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    nomeIconeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.levelmember.com/imagens/web/plano-expert.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    nomeTituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 20,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 15
      },
      text: 'DOLOR'
    },
    precoUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 40,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 997'
    },
    precoDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'cobrado mensalmente'
    },
    precoQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 16,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod'
    },
    precoCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 40,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 830'
    },
    precoSeisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoSeteQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '6D727B',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado Anualmente'
    },
    precoOitoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 16,
      color: '010A16',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 9.970 à vista'
    },
    botaoUmQuadroTres: {
      id: 'botaoPlanos',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: '5FC5E2',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'LOREM IPSUM',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    botaoDoisQuadroTres: {
      id: 'botaoPlanos',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '5FC5E2',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'ASSINAR EXPERT',
      link: 'https://pay.hotmart.com/P94506149E?off=shj5effm&checkoutMode=10',
      linkTarget: '_blank',
      gradienActive: false
    },
    listaParteUmQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteUmQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/883ed895335a35932e53e7-1.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteDoisQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDoisQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/83ed895335a35932e53e7d-2.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteTresQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteTresQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/3ed895335a35932e53e7d2-3.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/ed895335a35932e53e7d22-4.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteCincoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteCincoQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/d895335a35932e53e7d22e-5.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteSeisQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeisQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/895335a35932e53e7d22e6-6.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteSeteQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeteQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/95335a35932e53e7d22e6d-7.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeteQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteOitoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOitoQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/5335a35932e53e7d22e6d4-8.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOitoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteNoveQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteNoveQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/335a35932e53e7d22e6d4e-9.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteNoveQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteDezQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDezQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/35a35932e53e7d22e6d4eb-10.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDezQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteOnzeQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOnzeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/5a35932e53e7d22e6d4eba-11.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOnzeQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    },
    listaParteDozeQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDozeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/a35932e53e7d22e6d4eba0-12.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDozeQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor'
    }
  })
})
