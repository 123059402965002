<template>
  <nav ref="menuMobile" :class="{ free: storePerfil.usuario?.assinatura?.tipo === 'free' }">
    <Svgs class="logo" nome="logo-flat" @click="irParaRota('/sites')" />
    <!-- OpçÕes -->
    <div class="opcoes" :class="{ ativo: state.opcoesMobile }">
      <button class="opcao" :class="{ ativo: $route.path === '/sites' }" @click="irParaRota('/sites')">
        <Svgs nome="cursor" />
        <p>Seus sites</p>
      </button>
      <button class="opcao" :class="{ ativo: $route.path === '/templates' }" @click="irParaRota('/templates')">
        <Svgs nome="template" />
        <p>Templates</p>
      </button>
      <button class="opcao" :class="{ ativo: $route.path === '/compartilhados' }" @click="irParaRota('/compartilhados')">
        <Svgs nome="usuarios" />
        <p>Compartilhados</p>
      </button>
      <button class="opcao" :class="{ ativo: $route.path === '/unicflix' }" @click="irParaRota('/unicflix')">
        <Svgs nome="unicflix-icone" />
        <p>UnicFlix</p>
      </button>
      <div class="expandir" :class="{ ativo: state.opcoesFerramentas }" ref="expandirFerramentas">
        <button class="opcao" @click="state.opcoesFerramentas = !state.opcoesFerramentas">
          <Svgs nome="ferramentas" />
          <p>Ferramentas</p>
          <Svgs nome="setinha" class="setinha" />
        </button>
        <div class="balao">
          <div class="free" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">
            <img src="https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-free.svg" />
            <h3>Ferramentas Exclusivas</h3>
            <h4>Faça upgrade para ter acesso à 100% das funcionalidades Unic.</h4>
          </div>
          <div class="assinante" v-else>
            <button @click="router.push('/icones')">
              <div class="icone" style="background-color: #0fac87">
                <Svgs nome="icones" />
              </div>
              <p>Banco de Ícones</p>
            </button>
            <button @click="irParaLevel()">
              <div class="icone" style="background-color: #ff8612">
                <Svgs nome="level" />
              </div>
              <p>Criar área de membros</p>
            </button>
            <button @click="abrirModal('geradorLinkWhatsapp', 'whatsapp', 'Gerador de Link')">
              <div class="icone" style="background-color: #25d366">
                <Svgs nome="whatsapp" />
              </div>
              <p>Links para Whatsapp</p>
            </button>
            <button @click="abrirModal('geradorQrCode', 'ferramentas', 'Gerador de QRcode')">
              <div class="icone" style="background-color: #ff5d5d">
                <Svgs nome="qrcode" />
              </div>
              <p>Gerador de QRcode</p>
            </button>
            <button @click="storeModal.abrirModal('linkBioIa', 'link', 'Criar link de bio com Unika')">
              <div class="icone" style="background-color: #6e42f8">
                <Svgs nome="ia-flat" />
              </div>
              <p>Link de Bio com I.A</p>
            </button>
          </div>
        </div>
      </div>
      <div class="expandir" :class="{ ativo: state.opcoesAssinatura }" ref="expandirAssinatura">
        <button class="opcao" @click="state.opcoesAssinatura = !state.opcoesAssinatura">
          <Svgs nome="estrela" />
          <p>Assinatura</p>
          <Svgs nome="setinha" class="setinha" />
        </button>
        <div class="balao">
          <a href="https://api.whatsapp.com/send?phone=1831993895&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20na%20UnicPages" target="_blank">
            <Svgs nome="whatsapp" />
            <p>Suporte</p>
          </a>
          <a href="https://discord.gg/GjqvZMsV9r" target="_blank">
            <Svgs nome="discord" />
            <p>Comunidade Discord</p>
          </a>
          <button @click="irParaRota('/planos')">
            <Svgs nome="estrela" />
            <p>Planos Unic</p>
          </button>
          <button @click="irParaRota('/afiliados')">
            <Svgs nome="dinheiro" />
            <p>Indique a ganhe</p>
          </button>
        </div>
      </div>
    </div>
    <!-- Plano -->
    <button class="plano" @click="irParaRota('/planos')">
      <img :src="`https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/planos/icones/icone-flat-${storePerfil.usuario?.assinatura?.tipo || 'default'}.svg`" />
      <p>{{ storePerfil.usuario?.assinatura?.tipo }} {{ exibirPlano(storePerfil.usuario?.assinatura?.plano) }}</p>
    </button>
    <!-- Perfil -->
    <div class="perfil" :class="{ ativo: state.opcoesPerfil }" ref="expandirPerfil">
      <button class="abrir" @click="state.opcoesPerfil = !state.opcoesPerfil">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://central-statics.nyc3.digitaloceanspaces.com/unicpages/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
        <span></span>
      </button>
      <div class="balao">
        <div class="info">
          <h3>{{ (storePerfil.usuario?.nome || '').split(' ').slice(0, 2).join(' ') }}</h3>
          <p>{{ storePerfil.usuario?.email }}</p>
          <h4>Conta criada {{ exibirData(storePerfil.usuario?.dataRegistro) }}</h4>
        </div>
        <button @click="alterarTema()">
          <Svgs nome="tema" />
          <p>Modo {{ textoTema() }}</p>
        </button>
        <button @click="abrirModal('editarPerfil', 'editar', 'Editar Perfil')">
          <Svgs nome="editar" />
          <p>Editar Perfil</p>
        </button>
        <button @click="abrirModal('alterarSenha', 'cadeado', 'Alterar Senha')">
          <Svgs nome="cadeado" />
          <p>Alterar Senha</p>
        </button>
        <div class="linha"></div>
        <button @click="sair()">
          <Svgs nome="sair" />
          <p>Sair</p>
        </button>
      </div>
    </div>
    <!-- Menu Mobile -->
    <button class="menu" @click="state.opcoesMobile = !state.opcoesMobile">
      <Svgs nome="menu" />
    </button>
    <!-- Modals -->
    <Teleport to="body">
      <ModalEditarPerfil />
      <ModalAlterarSenha />
      <ModalLinkWhatsapp />
      <ModalGerarQrCode />
    </Teleport>
    <div class="faixa" @click="router.push('/planos')">
      <p>Você está no plano FREE, para ter acesso completo faça seu upgrade</p>
    </div>
  </nav>
  <div class="espacoNav" :class="{ free: storePerfil.usuario?.assinatura?.tipo === 'free' }"></div>
</template>

<script setup>
import Svgs from '@svgs'
import { reactive, ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStorePerfil, useStoreModal } from '@stores'
import { helperClicouFora } from '@helpers'
import ModalEditarPerfil from '@components/app/perfil/modals/ModalEditarPerfil.vue'
import ModalAlterarSenha from '@components/app/perfil/modals/ModalAlterarSenha.vue'
import ModalLinkWhatsapp from '@components/app/ferramentas/modals/ModalLinkWhatsapp.vue'
import ModalGerarQrCode from '@components/app/ferramentas/modals/ModalGerarQrCode.vue'

const router = useRouter()
const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const emitter = inject('emitter')

const expandirPerfil = ref(null)
const expandirSuporte = ref(null)
const expandirFerramentas = ref(null)
const expandirAssinatura = ref(null)
const menuMobile = ref(null)

const state = reactive({
  opcoesPerfil: false,
  opcoesSuporte: false,
  opcoesFerramentas: false,
  opcoesAssinatura: false,
  opcoesMobile: false,
  tema: JSON.parse(localStorage.getItem('personalizacao-unicpages'))?.tema
})

function abrirModal(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
  fecharOpcoes()
}

function irParaLevel() {
  window.open('https://levelmember.com', '_blank')
}

function exibirData(dataRegistro) {
  const data = new Date(dataRegistro)
  const agora = new Date()
  const diferencaEmMilissegundos = agora - data

  const minutos = Math.floor(diferencaEmMilissegundos / (1000 * 60))
  const horas = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60))
  const dias = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24))

  if (minutos < 60) {
    return `há ${minutos} minuto${minutos === 1 ? '' : 's'}`
  } else if (horas < 24) {
    return `há ${horas} hora${horas === 1 ? '' : 's'}`
  } else if (dias < 7) {
    return `há ${dias} dia${dias === 1 ? '' : 's'}`
  } else {
    const dia = data.getDate().toString().padStart(2, '0')
    const mes = (data.getMonth() + 1).toString().padStart(2, '0')
    const ano = data.getFullYear()
    return `em ${dia}/${mes}/${ano}`
  }
}

function exibirPlano(plano) {
  const valoresValidos = ['mensal', 'anual']
  return valoresValidos.includes(plano?.toLowerCase()) ? plano.toLowerCase() : ''
}

function sair() {
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Você saiu de sua conta Unic' })
  localStorage.removeItem('unicpages-app-access-token')
  router.push('/')
}

function textoTema() {
  if (state.tema === 'claro') return 'Escuro'
  else if (state.tema === 'escuro') return 'Claro'
  else return 'Cor'
}

function alterarTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-unicpages')) || {}
  state.tema = state.tema === 'claro' ? 'escuro' : 'claro'
  localStorage.setItem('personalizacao-unicpages', JSON.stringify({ ...dadosLocalStorage, tema: state.tema }))
  document.body.setAttribute('tema', state.tema)

  alterarCoresMeta(state.tema)
}

function alterarCoresMeta(tema) {
  const metaTags = {
    'theme-color': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'msapplication-TileColor': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'msapplication-navbutton-color': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'apple-mobile-web-app-status-bar-style': tema === 'claro' ? '#FFFFFF' : '#0C0C0E'
  }

  for (const [name, content] of Object.entries(metaTags)) {
    let metaTag = document.querySelector(`meta[name="${name}"]`)
    if (!metaTag) {
      metaTag = document.createElement('meta')
      metaTag.name = name
      document.head.appendChild(metaTag)
    }
    metaTag.content = content
  }
}

function fecharOpcoes() {
  state.opcoesPerfil = false
  state.opcoesSuporte = false
  state.opcoesFerramentas = false
  state.opcoesAssinatura = false
  state.opcoesMobile = false
}

function irParaRota(rota) {
  fecharOpcoes()
  router.push(rota)
}

helperClicouFora.clicouFora([
  {
    alvo: expandirPerfil,
    retornoChamada: () => (state.opcoesPerfil = false)
  },
  {
    alvo: expandirSuporte,
    retornoChamada: () => (state.opcoesSuporte = false)
  },
  {
    alvo: expandirFerramentas,
    retornoChamada: () => (state.opcoesFerramentas = false)
  },
  {
    alvo: expandirAssinatura,
    retornoChamada: () => (state.opcoesAssinatura = false)
  },
  {
    alvo: menuMobile,
    retornoChamada: () => (state.opcoesMobile = false)
  }
])
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  padding: 0 20px;
  background-color: var(--cor-cinza-2);
  z-index: 10;
}

nav.free {
  top: 25px;
}

.espacoNav {
  height: 60px;
}

.espacoNav.free {
  height: 85px;
}

nav.free .faixa {
  display: flex;
}

.faixa {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 25px;
  width: 100%;
  background-color: var(--cor-verde-escuro);
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
}

.faixa:hover {
  background-color: var(--cor-azul);
}

.faixa p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

svg.logo {
  width: 110px;
  min-width: 110px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.opcoes {
  display: flex;
  align-items: center;
  width: 100%;
}

.opcao {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 10px;
  border-radius: 50px;
  transition: all 0.3s;
}

.opcao:hover {
  opacity: 0.7;
}

.opcao.ativo {
  background-color: var(--cor-cinza-3);
  pointer-events: none;
}

.opcao.ativo p {
  color: var(--cor-cinza-5);
}

.opcao.ativo svg {
  fill: var(--cor-cinza-5);
}

.opcao svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 8px 0 0;
  transition: all 0.3s;
}

.opcao svg.setinha {
  margin: 0 0 0 5px;
  fill: var(--cor-cinza-5);
}

.opcao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.expandir {
  position: relative;
}

.expandir .balao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-3);
  padding: 15px 20px;
  border-radius: 10px;
  position: absolute;
  top: 60px;
  left: 0;
  width: 220px;
  min-width: 220px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.expandir.ativo .balao {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.expandir .balao .free {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0;
}

.expandir .balao .free img {
  width: 100%;
  max-width: 30px;
}

.expandir .balao .free h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 10px 0 5px 0;
}

.expandir .balao .free h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.expandir .balao button,
.expandir .balao a {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.expandir .balao button:hover,
.expandir .balao a:hover {
  opacity: 0.7;
}

.expandir .balao button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 5px;
  margin: 0 10px 0 0;
}

.expandir .balao button .icone svg {
  margin: 0;
}

.expandir .balao button svg,
.expandir .balao a svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.expandir .balao button img {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  border-radius: 5px;
  margin: 0 10px 0 0;
}

.expandir .balao button p,
.expandir .balao a p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.plano {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  border-radius: 50px;
  padding: 10px 20px;
  margin-left: auto;
  transition: all 0.3s;
}

.plano:hover {
  background-color: var(--cor-cinza-4);
}

.plano img {
  width: 100%;
  max-width: 15px;
  min-width: 15px;
  max-height: 15px;
  margin: 0 5px 0 0;
}

.plano p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
  white-space: nowrap;
}

.perfil {
  display: flex;
  position: relative;
}

.perfil .abrir {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.perfil .abrir:hover {
  opacity: 0.7;
}

.perfil .abrir .foto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cor-cinza-4);
  background-size: cover;
  background-position: center;
}

.perfil .abrir span {
  width: 10px;
  height: 10px;
  background-color: var(--cor-verde);
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}

.perfil .balao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-3);
  padding: 15px 20px;
  border-radius: 10px;
  position: absolute;
  top: 55px;
  right: 0;
  width: 240px;
  min-width: 240px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.perfil.ativo .balao {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.perfil .balao .linha {
  width: 100%;
  height: 2px;
  background-color: var(--cor-cinza-3);
  margin: 10px 0;
}

.perfil .balao button {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px 0;
  transition: all 0.3s;
}

.perfil .balao button:hover {
  opacity: 0.7;
}

.perfil .balao button svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.perfil .balao button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.perfil .balao .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.perfil .balao .info h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.perfil .balao .info p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 5px 0 5px 0;
}

.perfil .balao .info h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 15px 0 10px 0;
  border-top: 1px solid var(--cor-cinza-3);
  padding: 15px 0 0 0;
  width: 100%;
}

.menu {
  display: none;
  background-color: transparent;
  margin: 0 0 0 15px;
}

.menu svg {
  width: 20px;
  min-height: 20px;
  fill: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .opcoes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background-color: var(--cor-cinza-2);
    position: fixed;
    top: 50px;
    left: 0;
    padding: 20px 20px 20px 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s;
  }

  .opcoes.ativo {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .opcoes .opcao {
    margin: 5px 0;
    padding: 10px;
  }

  .expandir {
    position: relative;
  }

  .expandir .balao {
    display: none;
    background-color: transparent;
    border: none;
    padding: 0 0 0 20px;
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    min-width: 100%;
  }

  .expandir.ativo .balao {
    display: flex;
    animation: fadeIn 0.3s linear;
  }

  .perfil .abrir .foto {
    width: 30px;
    height: 30px;
  }

  .plano {
    padding: 10px 15px;
  }

  .plano img {
    max-width: 12px;
    min-width: 12px;
    max-height: 12px;
  }

  .plano p {
    font-size: var(--f0);
  }

  .menu {
    display: flex;
  }
}
</style>
